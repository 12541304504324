import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
// import axios from 'axios';

import Nationdetails from './nationdetails';
import Generaldetail from './gendetail';
import Personaldetail from './personaldetail';
// import Edicationdetail from './edicatdetail';
import Api from '../../../services/api/Api'


class RegcontentDs extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            update:{},
            country:[],
            stated:[],
            district:[],
            status:false,
            id:{},
            send:{},
            data:{
                a_name_omark: "",
                aadhar_no: "",
                address: "",
                address_current: "",
                address_perment: "",
                applied_on: "",
                cast_category: "",
                district: "",
                district_id: "",
                dob: "",
                email_id: "",
                father_name: "",
                first_name: "",
                gender: "",
                id: "",
                iynchange: "",
                job_role: "",
                last_name: "",
                last_update: "",
                marital_status: "",
                mobile_no: "",
                mother_name: "",
                mother_toug: "",
                name_change: "",
                nation_id: "",
                relation_type: "",
                religion: "",
                sector: "",
                state: "",
                state_id: "",
                student_id: "",
                temp_district_name: "",
                temp_id: "",
                temp_state_name: "",
            },
            
        }
        this.api = new Api();
    }

    updateData = (e) =>{
        
        let pdata = this.state.update;
        let name = e.target.name;
        let value = e.target.value;
            
        pdata[name] = value;

        this.setState({pdata});
        // console.log(this.state.update)


    }
    componentWillMount = () =>{


                let stud_id = localStorage.getItem('id');
                let ids = {"id":JSON.parse(stud_id)};
                this.setState({update:{"id":stud_id}});
                // console.log(ids);

                let res = this.api.apiCall("getdata",ids);
                res
                .then(res => {
                    this.setState({ data:res.sdata});
                        // console.log(res.data);
                    })
                .catch(error => { console.error(error); return Promise.reject(error); });

         
            let res2 = this.api.apiCallGet("state");
            res2
            .then(res => {
                //   const stated = res.data;
                //    let task = stated.filter(function(task) {
                //     return task.country_id === pdata[name];
                //     })
                  this.setState({ stated:res.data });
                })    
            .catch(error => { console.error(error); return Promise.reject(error); });
           
        
            let res1 = this.api.apiCallGet("district");
            res1
            .then(res => {
                const district = res.data;
                    // if(this.state.update.state !== ""){
                    //   let district = district.filter(function(district) {
                    //       return district.state_id === this.state.update.state;
                    //   })
                    // }
                    this.setState({ district });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
         
    }
    onUpdataData = () =>{

        let res4 = this.api.apiCall("Update",this.state.update);
            res4
            .then(res => {
                if(res.respond === 'sucess'){
                    this.setState({ status:true });
                }
            })
            .catch(error => { console.error(error); return Promise.reject(error); });
    
    }
    render() { 
        // console.log(this.state)
        // console.log(this.state.update.state)
        const { data  } = this.state;
        const s = this.state.stated
        const d = this.state.district;
        // const status = this.state.status
        return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:50,marginBottom:50,padding:50}}>
                    <div className="jumbotron" style={{borderRadius:10,backgroundColor:'#b9bbbe'}}>
                        <h5 className="font-weight-bold" style={{padding:10}}>Edit Details</h5>
                        {/* { status => {if(status){
                            return(<span className="text-success">Sucessfully Updated</span>);
                            }}} */}
                        <div className="row">
                            <div className="col-2">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="v-pills-h-tab" data-toggle="pill" href="#v-pills-h" role="tab" aria-controls="v-pills-h" aria-selected="true">General Details</a>
                                <a className="nav-link" id="v-pills-p-tab" data-toggle="pill" href="#v-pills-p" role="tab" aria-controls="v-pills-p" aria-selected="false">Personal Details</a>
                                <a className="nav-link" id="v-pills-m-tab" data-toggle="pill" href="#v-pills-m" role="tab" aria-controls="v-pills-m" aria-selected="false">Location Details</a>
                                {/* <a className="nav-link" id="v-pills-se-tab" data-toggle="pill" href="#v-pills-se" role="tab" aria-controls="v-pills-se" aria-selected="false">Edication Details</a> */}
                                </div>
                            </div>
                            <div className="col-10" style={{backgroundColor:'white',padding:20,borderColor:'#007bff'}}>
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-h" role="tabpanel" aria-labelledby="v-pills-h-tab">
                                    <Generaldetail  data={data} updateData={this.updateData}/>
                                </div>
                                <div className="tab-pane fade" id="v-pills-p" role="tabpanel" aria-labelledby="v-pills-p-tab">
                                    <Personaldetail data={data} updateData={this.updateData} />                               
                                </div>
                                <div className="tab-pane fade" id="v-pills-m" role="tabpanel" aria-labelledby="v-pills-m-tab">
                                    <Nationdetails data={data} stated={s} district={d} updateData={this.updateData} />
                                </div>         
                                {/* <div className="tab-pane fade" id="v-pills-se" role="tabpanel" aria-labelledby="v-pills-se-tab">
                                    <Edicationdetail/>         
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{padding:20}} >
                        <button onClick={this.onUpdataData} className="btn btn-primary btn-md mx-auto">Save</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default RegcontentDs;