import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import './prog.css';
import { Card } from 'react-bootstrap'
import Api from '../../../services/api/Api'

class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            id:"",
            data:{}
         }
        this.api = new Api();
    }
    componentWillMount = () =>{

        let corp_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(corp_id)};
        this.setState({id:ids});
        
        // let  { id } = this.state;

        let res = this.api.apiCall("getdataCorp",ids);
        res
        .then(result => {
            if(result.respon === 'sucess'){
                    this.setState({data:result.sdata})             
                    }
                    else{
                    this.setState({err:'Invalid Credencial'})
                    // console.log('fail');
                    }  
                // console.log(result);
            })
        .catch(error => { console.error(error); this.setState({data:''}) });

    }
    render() { 
        let { organization_name , email_id , phone , contact_name } = this.state.data
        // console.log(this.props.id);
        // console.log(this.state.id);
        return ( 
            <React.Fragment>
                <React.Fragment>
                <br/>
                <br/>
                <div className="container" style={{marginTop:50}}>
                <h5 className="font-weight-bold alert-primary" style={{padding:10}}>Progress Bar</h5>
                <div className="card-header">
                <div className="row">
                    <div className="col-12">
                    <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h5 className="p-1">
                                       Industrial Partner Profile Progress
                                    </h5> 
                                    <div className="progress blue">
                                        <span className="progress-left">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <span className="progress-right">
                                            <span className="progress-bar"></span>
                                        </span>
                                        <div className="progress-value">
                                            <FontAwesomeIcon className="text-primary"  icon={faBuilding} style={{width:90,height:90,paddingTop:35}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">

                                <Card>
                                    <Card.Header as="h5">Industrial Name : <b> { organization_name } </b></Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                        Contact Person Name : { contact_name }
                                        </Card.Text>
                                        <Card.Text>
                                        Phone Number : { phone }
                                        </Card.Text>
                                        <Card.Text>
                                        Email Id : { email_id }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                </div>
                                </div>
                                    <div className="row">
                                    <div className="col">
                                    <h5 className="p-1">
                                            Internship Progress
                                        </h5> 
                                        <div className="progress yellow">
                                            <span className="progress-left">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                                <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value">75%</div>
                                        </div>
                                    </div>
                                    <div className="col">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </React.Fragment>
            </React.Fragment>
         );
    }
}
 
export default Progress;