import React, { Component } from 'react';
import BranchCentre from './branchs';
// import CentreCourses from './centrecourses';
// import PostedApplication from './postapplied';
// import Applied from './applied';
import ShortList from './shortlisting';
// import { Route ,
//     //  HashRouter ,
//     BrowserRouter as Router ,
//       NavLink } from 'react-router-dom';
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory()


class ManageCentre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:this.props.id,
         }
    }
    render() { 

        const id  = this.props.id

        return ( 
            
            <React.Fragment>
               
                <div className="container-fluid" style={{marginTop:70,padding:4}}>
                    <div className="jumbotron-fluid">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="nav-Shortlisted-tab" data-toggle="tab" href="#nav-Shortlisted" role="tab" aria-controls="nav-Shortlisted" aria-selected="false">Student</a>
                        <a className="nav-item nav-link" id="nav-Posted-tab" data-toggle="tab" href="#nav-Posted" role="tab" aria-controls="nav-Posted" aria-selected="true">Branch</a>
                        {/* <a className="nav-item nav-link" id="nav-Applied-tab" data-toggle="tab" href="#nav-Applied" role="tab" aria-controls="nav-Applied" aria-selected="false">Programs</a> */}
                        </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-Shortlisted" role="tabpanel" aria-labelledby="nav-Shortlisted-tab">
                            <ShortList id={id}/>
                            </div>   
                            <div className="tab-pane fade " id="nav-Posted" role="tabpanel" aria-labelledby="nav-Posted-tab">
                            <BranchCentre/>
                            </div>
                            {/* <div className="tab-pane fade" id="nav-Applied" role="tabpanel" aria-labelledby="nav-Applied-tab">
                            <CentreCourses/>
                            </div> */}
                        </div>
                    
                    </div>
                </div>
            
            </React.Fragment>
         );
    }
}
 
export default ManageCentre;