import React, { Component } from 'react';
import Api from '../../../services/api/Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus , faMinus , faSave } from '@fortawesome/free-solid-svg-icons'
import { Button , Form } from 'react-bootstrap';


class EditPrograms extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            id:this.props.id,
            alt:false,
            programs:[],
            validated:false,
            data:[]
         }
        this.api = new Api();

    }

    addProgram = () =>{

        let programData = {prg_name:"",skill_set:"",role:""};

        var programs = this.state.programs.concat([programData]);
        this.setState({programs});

    }

    handleDelete = i => e => {
        e.preventDefault()
        let programs = [
          ...this.state.programs.slice(0, i),
          ...this.state.programs.slice(i + 1)
        ]
        this.setState({
            programs
        })
      }


    handleText = i => e => {
        e.preventDefault();
        let programs = [...this.state.programs]
        let name = e.target.name;
        programs[i][name] = e.target.value
        this.setState({
            programs
        })
        // console.log(name);
      }

      handleSave = i => e =>{

        // console.log("woring");

      }
    // componentWillMount = () => {

    //     let corp_id = localStorage.getItem('id');
    //     let ids = {"id":JSON.parse(corp_id)};
    //     this.setState({id:ids});

    //     let res = this.api.apiCall("internshipPostedData",ids);
    //     res
    //     .then(res => {
    //         if(res.respond === 'success'){
    //             this.setState({ post:res.data,alt:true});
    //         }else{
    //             this.setState({alt:false})
    //         }
    //         console.log(res.data)
    //     })
    //     .catch(error => { console.error(error); return Promise.reject(error); });

    // }
    tableData = (programs) =>{
        // const { validated } = this.state;
        if(programs){
            return(
                <tbody>
                {programs.map((programs,index) =>
                    
                <tr key={index}>
                    {/* <td className="text-center align-middle">{index + 1}</td> */}
                    <td className="text-center" >
                    <Form.Control
                    required
                    type="text"
                    onChange={this.handleText(index)} 
                    name="prg_name"
                    placeholder="Program name"
                    value={programs.prg_name}
                    />
                    </td>
                    <td className="text-center">
                    <Form.Control
                    required
                    type="text"
                    onChange={this.handleText(index)} 
                    name="skill_set"
                    placeholder="skill set"
                    value={programs.skill_set}
                    />           
                    </td>   
                    <td className="text-center">  
                    <Form.Control
                    required
                    type="text"
                    onChange={this.handleText(index)} 
                    placeholder="Role"
                    value={programs.role}
                    name="role"
                    />                      
                    </td>
                    <td className="text-center">
                    <Button variant="danger" onClick={this.handleDelete(index)}>Delete   <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon></Button>&ensp;
                    <Button variant="success" onClick={this.handleSave(index)}>Save   <FontAwesomeIcon icon={faSave}></FontAwesomeIcon></Button>
                    </td>
                </tr>
                )}
            </tbody>
            );
        }
    }
    render() {
        // console.log(this.state.programs);
        const { programs } = this.state
        return ( 
            <React.Fragment>
                 <div className="container-fluid" style={{marginTop:20,padding:4}}>
                    <div className="jumbotron-fluid">
                        <table className="table table-sm">
                            <thead className="thead-dark">
                                <tr>
                                {/* <th scope="col" className="text-center">S.No</th> */}
                                <th scope="col" className="text-center">Program Name</th>
                                <th scope="col" className="text-center">Skill Set</th>
                                <th scope="col" className="text-center">Role</th>
                                {/* <th scope="col" className="text-center">State</th>
                                <th scope="col" className="text-center">District</th> */}
                                <th scope="col"  className="text-center">
                                <Button onClick={this.addProgram} variant="primary">Add  <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon></Button></th>
                                </tr>
                            </thead>
                            {this.tableData(programs)}
                        </table>
                        <div>
                            
                        </div>
                    </div>
                </div>

            </React.Fragment>
         );
    }
}
 
export default EditPrograms;