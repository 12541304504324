import React, { Component } from 'react';
import { withRouter }  from 'react-router-dom';
import { Form , Button , Col} from 'react-bootstrap';
import Api from '../../../services/api/Api'


class RegisterCrop extends Component {

    constructor(props){
        super(props);
        this.state = { 
            data:{},
            formvalue:{},
            err:{},
            respon:'',
            district:[],
            stated:[]          
        }
        this.api = new Api();
        
    }
    //     this.dataValidate();

    // }
    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setState({ validated: true });
        // console.log('success');
      }

    
     componentWillMount() {
     
        let corp_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(corp_id)};
        this.setState({id:ids});
        // console.log(corp_id);        
        // let { id } = this.state;

        
        let res = this.api.apiCall("getdataCorp",ids);
        res
        .then(result => {
            if(result.respon === 'sucess'){
                    this.setState({data:result.sdata})             
                    }
                    else{
                    this.setState({err:'Invalid Credencial'})
                    // console.log('fail');
                    }  
                // console.log(result);
            })
        .catch(error => { console.error(error); this.setState({data:''}) });

            let res1 = this.api.apiCallGet("state");
            res1
            .then(res => {
            
                this.setState({ stated:res.data });
           
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
        
            let res2 = this.api.apiCallGet("district");
            res2
            .then(res => {
                const district = res.data;
                this.setState({ district });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
        // axios({
        //         method: 'post',
        //         url: this.state.url+"?type=getCrop",
        //         data: this.state.id,
        //         config: { headers: {'Content-Type': 'application/json'}}
        //          })
        //         .then(res => {
        //             if(res.data.respond === 'success'){
        //                 this.setState({data:res.data.data})             
        //             }else{
        //                 // this.setState({err:'Invalid Credencial'})
        //                 console.log('fail');
        //             }  
        //             // console.log(res.data);
        //         })
    }

    updateState = (e) =>{

        // this.dataValidate();
        
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        // console.log(this.state.data);

    }
    
    render() { 
        // console.log(this.props);
        // console.log(this.state.id);
        // console.log(this.state.data[0].first_name);
        let { district , stated } = this.state
        let { contact_name, organization_name, pincode, area, city, address,
             state_id, district_id,
              email_id, phone } = this.state.data;        
        return (     
            <React.Fragment>

                <div style={{marginTop:60,marginBottom:0}} className="jumbotron">
                <div className="container alert alert-dark text-center" role="alert">
                {/* {this.state.name}  */}
                Edit Details</div>
                <div className="card">
                    <div className="card-body">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} >
                            <Form.Label>Organisation Name</Form.Label>
                            <Form.Control 
                            type="text"
                            name="organization_name"
                            defaultValue={organization_name || ''} disabled={true}
                            // placeholder="Enter email" 
                            onChange={this.updateState}
                            />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Contact Person Name</Form.Label>
                            <Form.Control
                            type="text"
                            name="c_Name"
                            defaultValue={contact_name || ''} disabled={true}
                            // placeholder="Password" 
                            />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>Contact Person Mobile No</Form.Label>
                            <Form.Control 
                            type="number"
                            name="Mobile"
                            defaultValue={phone || ''} disabled={true}
                            // placeholder="1234 Main St" 
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>Organisation Email</Form.Label>
                            <Form.Control 
                            type="email"
                            name="Email"
                            defaultValue={email_id || ''} disabled={true}
                            // placeholder="1234 Main St" 
                            />
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>

                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Organisation Address</Form.Label>
                            <Form.Control 
                            defaultValue={address || ''}
                            name="Address"
                            onChange={this.updateState}
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Area</Form.Label>
                            <Form.Control 
                            defaultValue={area || ''}
                            name="Area"
                            // placeholder="Apartment, studio, or floor"
                            onChange={this.updateState}

                             />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                            defaultValue={city || ''}
                            onChange={this.updateState}

                            name="City"
                            //  placeholder="Apartment, studio, or floor"
                              />
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>District</Form.Label>
                            <Form.Control 
                            name="district_id"
                            as="select"
                            value={district_id}
                            onChange={this.updateState}

                            >
                               <option>..choose..</option>
                                {  district.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                            )}
                            
                            </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                            name="state_id"
                            as="select"
                            value={state_id}
                            onChange={this.updateState}

                            >

                                 <option>..choose..</option>
                                 { stated.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                            </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                            defaultValue={pincode || ''}
                            onChange={this.updateState}

                            name="pincode"
                            />
                            </Form.Group>
                        </Form.Row>

                        {/* <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}

                        <Button variant="primary" onClick={this.handleSubmit} type="button">
                            Save
                        </Button>
                        </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(RegisterCrop);