import React, { Component } from 'react';
// import { error } from 'util';
// import $ from 'jquery';
import Api from '../../services/api/Api'


class Nationdetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{},
            country:[],
            stated:[],
            district:[],
         }
        this.api = new Api();

    }
    componentDidMount = () =>{
       
        let res = this.api.apiCallGet("country");
        res
        .then(res => {
            const country = res.data;
            this.setState({ country });
          //   console.log(persons);
          })
        .catch(error => { console.error(error); return Promise.reject(error); });
        // axios.post(this.state.url+"?type=country")
        // .then(res => {
        //   const country = res.data;
        //   this.setState({ country });
        // //   console.log(persons);
        // })

    }

    render() { 
        // console.log(this.props.stated);
        let i = this.state.country; 
        let j = this.props.stated; 
        let k = this.props.district; 

        return ( 
            <React.Fragment>
                <fieldset className="card p-4 m-2">
            <div className="form-group row">
                <label  className="col-sm-2 col-form-label">Country</label>
                    <div className="col-sm-2">
                    <select className="custom-select" name="country" onChange={this.props.updateData}>
                        <option defaultValue>Choose...</option>
                        { i.map((country,indx) => <option key={indx} value={country[0]}>{country[2]}</option>
                            )}
                    </select>
                    </div>
                    <label  className="col-sm-2 col-form-label">State</label>
                    <div className="col-sm-2">
                    <select className="custom-select" name="state" onChange={this.props.updateData} >
                        <option defaultValue>Choose...</option>
                        { j.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                    </select>
                    </div>
            </div>
                <div className="form-group row">
                    <label  className="col-sm-2 col-form-label">District</label>
                        <div className="col-sm-2">
                        <select className="custom-select" onChange={this.props.updateData} name="district">
                            <option defaultValue>Choose...</option>
                            {  k.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                            )}
                        </select>
                        </div>
                        {/* <label  className="col-sm-2 col-form-label">Taluk</label>
                        <div className="col-sm-2">
                        <select className="custom-select" id="inputGroupSelect01">
                            <option defaultValue>Choose...</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                        </select>
                        </div> */}
                </div>
                <div className="form-group row">
                    <label  className="col-sm-2 col-form-label">Current Address</label>
                        <div className="col-sm-4">
                        <input type="textarea"  name="address" onChange={this.props.updateData} className="form-control"/>
                        </div>
                </div>
                </fieldset>
                {/* <button className="btn btn-primary btn-md m-3" onClick={this.props.moveNext}>Next</button>
                <button className="btn btn-primary btn-md" onClick={this.props.moveBack}>Back</button> */}
</React.Fragment>
         );
    }
}
 
export default Nationdetails;