import React, { Component } from 'react';

class Footer extends Component {
    // state = {  }
    render() { 
        return ( 
            <footer>
                <hr/>
                 <div className="container" style={{textAlign:'center'}}>
                    <p className="p-4 md">
                    Office : Broadline Technologies Pvt. Ltd.
# 7, Khader Nawaz Khan Road, Nungambakkam, Chennai - 600 006 <br/>
Phone: +44 45500300
                    </p>
                 </div>
            </footer>
         );
    }
}
 
export default Footer;