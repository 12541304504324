import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { library } from '@fortawesome/fontawesome-svg-core'
import { Redirect } from 'react-router-dom'
import Nationdetails from './nationdetails';
import Generaldetail from './gendetail';
import Personaldetail from './personaldetail';
// import Edicationdetail from './edicatdetail';
import Api from '../../services/api/Api'
// import EditEdcation from './edudet';



class Regcontent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            pdata:{
                id:"",
                address: "",
                aname: "",
                anumber: "",
                changed: "",
                country: "",
                district: "",
                dob: "",
                father_name: "",
                mother_name: "",
                mstatus: "",
                mtongue: "",
                regname: "",
                state: "",
                yncqual: "",
                ed_detail:[]
            },
            id:{},
            test:{id:1014},
            res_id:{},
            popup:{},
            country:[],
            stated:[],
            district:[],
            programs:[],
            // url:'http://192.168.1.21/bskill/src/db.php',
            redirecrt:false,
            validated : false
         }
        this.api = new Api();

    }
    updateData = (e) =>{
        
        let pdata = this.state.pdata;
        let name = e.target.name;
        let value = e.target.value;
            
        pdata[name] = value;

        this.setState({pdata});
        
        if(name === 'country'){

            let res2 = this.api.apiCallGet("state");
            res2
            .then(res => {
                //   const stated = res.data;
                //    let task = stated.filter(function(task) {
                //     return task.country_id === pdata[name];
                //     })
                  this.setState({ stated:res.data });
                //   console.log(this.state.stated);
                //   console.log(data[name]);
                //   console.log(task);
                })
            .catch(error => { console.error(error); return Promise.reject(error); });
        }
    
        if(name === 'state'){

            let res1 = this.api.apiCallGet("district");
            res1
            .then(res => {
                const district = res.data;
                // let dst = district.filter(function(dst) {
                //   return dst.state_id === pdata[name];
                //   })
                this.setState({ district });
              //   console.log(this.state.district);
              //   console.log(data[name]);
              //   console.log(dst);
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
        }

        // console.log(pdata);

    }

    componentWillMount = () => {

        let id = localStorage.getItem('id')   
        this.setState({pdata:{id:id}});
    }
    onUpdataData = () =>{

        // console.log(this.state.programs);
        // console.log(this.state.pdata);

        let res = this.api.apiCall("Apply",this.state.pdata);
        res
        .then(res => {
            if(res.respond === 'sucess'){
               
                this.setState({redirecrt:true,
                    res_id:res});
                    localStorage.setItem('id',this.state.pdata.id);
                    // console.log(res.id)
                // console.log(res);
            }else{
                // console.log(res.respond);
            }
        })
        .catch(error => { console.error(error); return Promise.reject(error); });

        // axios({
        //     method:'post',
        //     url:this.state.url+"?type=Apply",
        //     data:this.state.pdata,
        //     config: { headers: {'Content-Type': 'application/json'}}
        // })
        // .then(res => {
        //     if(res.data.respond === 'sucess'){
               
        //         this.setState({redirecrt:true,
        //             res_id:res.data});
        //         console.log(res.data);
        //     }else{
        //         console.log(res.data.respond);
        //     }
        // })
    }

    // addProgram = () =>{

    //     // console.log("work add prog");

    //     let programData = {Board: "",
    //     CGPA: "",
    //     Marks: "",
    //     Passout: "",
    //     Qualification: "",
    //     Stream: ""};

    //     var programs = this.state.programs.concat([programData]);
    //     this.setState({programs});

    // }

    // handleDelete = i => e => {
    //     e.preventDefault()
    //     let programs = [
    //       ...this.state.programs.slice(0, i),
    //       ...this.state.programs.slice(i + 1)
    //     ]
    //     this.setState({
    //         programs
    //     })

        // var programsd = this.state.programs;
        // let id = localStorage.getItem('id')   

        // console.log("woring");
        // this.setState({pdata:{ed_detail:programsd,id:id}})
    //   }


    // handleText = i => e => {
    //     e.preventDefault();
    //     let programs = [...this.state.programs]
    //     let name = e.target.name;
    //     programs[i][name] = e.target.value
    //     this.setState({
    //         programs
    //     })

    //     // console.log(this.state.programs)
       
    //   }

    //   handleSave = i => e =>{


    //     var programs = this.state.programs;
    //     let id = localStorage.getItem('id')   

    //     console.log("woring");
    //     this.setState({pdata:{ed_detail:programs,id:id}})

    //   }

    render() { 
        // console.log(this.state.pdata.id)
        // console.log(this.props.data.params);
        // const senddata = this.props.data.params;
        const s = this.state.stated
        const d = this.state.district;
        const id = this.state.pdata.id;
        let { validated } = this.state
        const redirect = this.state.redirecrt;
        if(redirect){
        
            return( 
                <Redirect to={{
                    pathname:"/student/dashboard",
                }}/>
                );
                
            }else{
                // console.log(this.props);            
                return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:50,marginBottom:50,padding:40}}>
                    <div className="jumbotron" style={{borderRadius:10}}>
                    <h4 className="mx-auto text-justify font-weight-bold">Registration Form</h4>
                        <div className="row">
                            <div className="col-2">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <a className="nav-link active" id="v-pills-h-tab" data-toggle="pill" href="#v-pills-h" role="tab" aria-controls="v-pills-h" aria-selected="true">General Details</a>
                                <a className="nav-link" id="v-pills-p-tab" data-toggle="pill" href="#v-pills-p" role="tab" aria-controls="v-pills-p" aria-selected="false">Personal Details</a>
                                <a className="nav-link" id="v-pills-m-tab" data-toggle="pill" href="#v-pills-m" role="tab" aria-controls="v-pills-m" aria-selected="false">Location Details</a>
                                {/* <a className="nav-link" id="v-pills-se-tab" data-toggle="pill" href="#v-pills-se" role="tab" aria-controls="v-pills-se" aria-selected="false">Edication Details</a> */}
                                </div>
                            </div>
                            <div className="col-10" style={{backgroundColor:'white',padding:20,borderColor:'#007bff'}}>
                                <div className="tab-content" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-h" role="tabpanel" aria-labelledby="v-pills-h-tab">
                                        <Generaldetail data={id} validated={validated} updateData={this.updateData}/>
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-p" role="tabpanel" aria-labelledby="v-pills-p-tab">
                                        <Personaldetail updateData={this.updateData}/>                               
                                    </div>
                                    <div className="tab-pane fade" id="v-pills-m" role="tabpanel" aria-labelledby="v-pills-m-tab">
                                        <Nationdetails stated={s} district={d} updateData={this.updateData}/>
                                    </div>         
                                    {/* <div className="tab-pane fade" id="v-pills-se" role="tabpanel" aria-labelledby="v-pills-se-tab">
                                        <EditEdcation handleText={this.handleText} addProgram={this.addProgram} handleSave={this.handleSave} handleDelete={this.handleDelete} programs={programs} />         
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{padding:20}} >
                        <button onClick={this.onUpdataData} className="btn btn-primary btn-md mx-auto">Submit</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
        }
    }
}
 
export default Regcontent;