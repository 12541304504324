import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import NavBarapp from './components/navbar_app'; 
import Content from './components/content';
import Logins from './components/logins';
import Footer from './components/footer';
// import { BrowersRouter , Route } from 'react-router-dom';
// import Route from 'react-router-dom/Route'
 
class Indx extends Component {
  render() {
    return (
    
            <div>
              <NavBarapp />
              <Content />
              <Logins />
              <Footer />
            </div>
    );
  }
}

export default Indx;
