import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars ,
    //  faUserCircle ,
    //   faBell , 
      faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
// import user from '../../images/userimg.png';
import './dash.css';
import './dashquery.js';
import './com.css';
// import Regcontent from '../student/students';
import { 
    withRouter , 
    // Redirect ,
    Route ,
    Link ,
    // BrowserRouter as Router,
    HashRouter as Router
        }from 'react-router-dom';
import RegcontentDs from './students_ds';
import Progress from './progress';
import CourseDetail from './course';
import StuHome from './studhome'
// import axios from 'axios';
import { createBrowserHistory } from "history";
import Api from '../../../services/api/Api'

const history = createBrowserHistory()


class Navdashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{},
            toggle:"toggled",
            id:"",
            url:this.props.id.url,
         }
        this.api = new Api();

    }
    logout = () =>{
        localStorage.clear();
        window.location.href = '/';
    }
    componentWillMount = () =>{
       
        let stud_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(stud_id)};
        this.setState({id:ids});
        // console.log(ids);

        let res = this.api.apiCall("getStudentData",ids);
        res
        .then(res => {
              this.setState({ data:res.sdata});
                // console.log(res.sdata);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    toggle = () =>{
        
        const tg = this.state.toggle;
        if(tg === "toggled"){
            this.setState({toggle:""});
        }else{
            this.setState({toggle:"toggled"});
        }
        
    }
    render() { 
        // console.log(this.props.id);
        // console.log(this.state);
        const { ids , data} = this.state;
        const { id , first_name } = this.state.data;
        return ( 
            <React.Fragment>
        <Router browserHistory={history} basename="/student/dashboard/">

                 <nav className="navbar navbar-expand navbar-dark fixed-top" style={{backgroundColor:'#17aafa'}}> 
                    <button onClick={this.toggle} style={{backgroundColor:'#17aafa'}} id="menu-toggle" className="navbar-brand btn">
                        <FontAwesomeIcon className="fa-2x" icon={faBars}/> 
                    </button>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation"></button>
                    <div className="collapse navbar-collapse" id="navbarsExample02">
                        <h1 className="my-2 my-md-0" style={{color:'white'}}>
                        
                        </h1>
                    </div>
                        {/* <button className="btn  my-2 my-sm-0"  ><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faBell}/> <span className="badge badge-light"></span></button> */}
                        {/* <button className="btn btn-primary my-2 my-sm-0"><FontAwesomeIcon style={{height:30,width:30}} icon={faUserCircle}/></button> */}
                        <button onClick={this.logout} className="btn my-2 my-sm-0" title="Logout"><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faSignOutAlt}/></button>
                </nav>

                <div id="wrapper" className={this.state.toggle} >
            
            <div id="sidebar-wrapper" style={{backgroundColor:'#b9bbbe',color:'black'}} >
                    <div className="sidebar-nav nav flex-column nav-tab"  id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <ul className="sidebar-nav">
                    <li><h6 className="p-1 h6" style={{marginTop:80}}>  </h6></li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/",
             
                    }} >Home</Link></li> 
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/edit",

                    }} >Edit Profile</Link> </li>
                    {/* <li><Link  className="nav-link text-dark" to={{
                        pathname:"/Apply",

                    }} >Apply Online</Link> </li> */}
                    <li><Link className="nav-link text-muted disabled" to={{
                        pathname:"/payment",

                    }} >Payment</Link></li>
                    <li><Link   className="nav-link text-dark" to={{
                        pathname:"/course",

                    }} >Course</Link> </li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/Profile",

                    }} >Profile progress</Link> </li>
                </ul>
                    </div>
            </div> 
            
            <div id="page-content-wrapper">
                <div className="container-fluid">
                            <Route path="/" exact={true} strict render={ (props) =>{
                                return(
                                    <StuHome id={id} name={first_name}/>                                                       
                                     );
                                }
                                }/>
                            <Route path="/edit" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <RegcontentDs id={ids}/>
    
                                        );
                                }
                                }/>
                            {/* <Route path="/Apply" exact={false}  strict render={ (props) =>{
                                    return(
                                        
                                        <div></div>
                                        );
                                }
                                }/> */}
                            <Route path="/profile" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <Progress status={data}/>
                                        );
                                }
                                }/>
                                <Route path="/course" exact={false} strict render={ (props) =>{
                                    return(
                                        
                                        <CourseDetail coursesid={id}/>
                                        
                                        );
                                }
                                }/>
                                
                    </div>
                </div> 
            </div> 
            </Router>
            </React.Fragment>
         );
    }
}
 
export default withRouter(Navdashboard);