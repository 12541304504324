import React, { Component } from 'react';
import Navdashboard from './nav';

class Centredash extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {

        let corp_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(corp_id)};
        this.setState({id:ids});
        // console.log(ids)
        
    }
    render() { 
        // console.log(this.props)
        const { id } = this.state
        return ( 
            <React.Fragment>
                <Navdashboard id={id} />
            </React.Fragment>
         );
    }
}
 
export default Centredash;