import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars ,
    //  faBell , 
     faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { withRouter , 
    Link ,
    //  BrowserRouter as Router, 
     HashRouter  as Router,
    Route 
}from 'react-router-dom';
import CentreHome from './centrehome';
import EditCentre from './editCentre'
import Api from '../../../services/api/Api'
import IndusPost from './indus_post';
// import Progress from '../corp_dash/progress';
import ManageCentre from './managecentre';
import ProgressCentre from './progresscentre';
import ProgramsBatch from './programs';


class Navdashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            toggle:"toggled",
            id:this.props.id,
            data:{}
         }
        this.api = new Api();
    }

    toggle = () =>{
        
        const tg = this.state.toggle;
        if(tg === "toggled"){
            this.setState({toggle:""});
        }else{
            this.setState({toggle:"toggled"});
        }
        
    }
    componentWillMount() {

        let res = this.api.apiCall("getdataCentre",this.props.id);
        res
        .then(result => {
              this.setState({ data:result.sdata});
                // console.log(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    render() { 
        // console.log(this.state.id)
        const { centre_name  } = this.state.data;
        const { id } = this.state;
        // console.log(id)
        return ( 
    <Router basename="/centre/dashboard/">

            <React.Fragment>
                 <nav className="navbar navbar-expand navbar-dark fixed-top" style={{backgroundColor:'#17aafa'}}> 
                    <button onClick={this.toggle} style={{backgroundColor:'#17aafa'}} id="menu-toggle" className="navbar-brand btn">
                        <FontAwesomeIcon className="fa-2x" icon={faBars}/> 
                    </button>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation"></button>
                    <div className="collapse navbar-collapse" id="navbarsExample02">
                        <h1 className="my-2 my-md-0" style={{color:'white'}}>
                        
                        </h1>
                    </div>
                        {/* <button className="btn  my-2 my-sm-0"  ><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faBell}/> <span className="badge badge-light"></span></button> */}
                        {/* <button className="btn btn-primary my-2 my-sm-0"><FontAwesomeIcon style={{height:30,width:30}} icon={faUserCircle}/></button> */}
                        <button onClick={this.logout} className="btn my-2 my-sm-0" title="Logout"><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faSignOutAlt}/></button>
                </nav>
                <div id="wrapper" className={this.state.toggle} >
            
            <div id="sidebar-wrapper" style={{backgroundColor:'#b9bbbe',color:'black'}} >
                    <div className="sidebar-nav nav flex-column nav-tab"  id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <ul className="sidebar-nav">
                    <li><h6 className="p-3 h4" style={{marginTop:50}}> </h6></li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/",
                     
                    }} >Home</Link></li> 
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/edit",
                
                    }} >Edit Profile</Link> </li>
                    <li><Link className="nav-link text-dark" to={{
                        pathname:"/manage",
                  
                    }} >Manager</Link> </li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/program",
    
                    }} >Programs/Batchs</Link> </li>
                    <li><Link className="nav-link text-muted disabled" to={{
                        pathname:"/payment",

                    }} >Payment</Link></li>
                    <li><Link   className="nav-link text-dark" to={{
                        pathname:"/requment",
         
                    }} >Industrial Requirement</Link> </li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/Profile",
                
                    }} >Profile progress</Link> </li>
                </ul>
                    </div>
            </div> 
            
            <div id="page-content-wrapper">
                <div className="container-fluid">
               
                            <Route path="/" exact={true} strict render={ (props) =>{
                                return(
                                    <CentreHome id={id} name={centre_name}/>                   
                                        );
                                }
                                }/>
                            <Route path="/edit" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <EditCentre id={id} />
                                        );
                                }
                                }/>
                            <Route path="/requment" exact={false}  strict render={ (props) =>{
                                    return(
                                        
                                        <IndusPost id={id} />
                                        );
                                }
                                }/>
                            <Route path="/profile" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <ProgressCentre id={id}/>
                                        );
                                }
                                }/>
                                <Route path="/program" exact={false} strict render={ (props) =>{
                                    return(
                                        
                                        <ProgramsBatch id={id}/>
                                        );
                                }
                                }/>
                                <Route path="/manage" exact={true} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                            <ManageCentre id={id}/>
                                        );
                                }
                                }/> 
                                
              </div>
            </div> 
        </div> 
    
            
            </React.Fragment>
            </Router>
         );
    }
    logout = () =>{
        
        localStorage.clear();
        window.location.href = '/';
    }
}
 
export default withRouter(Navdashboard);