import React, { Component } from 'react';
import { Col , Button , Form , Row } from 'react-bootstrap'
import { 
    // withRouter , 
    Redirect 
}  from 'react-router-dom'
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();
// import  { Route , Redirect}  from 'react-router';
// import passwordHash from 'password-hash';
// import Cryptr from 'cryptr';
import Api from '../../services/api/Api'


class RegisterForm extends Component {
    constructor(props){
        super(props);
        this.state = { 
                id:{},
            login : this.props.params,
            data:{
                first_name:'',
                last_name:'',
                relatype:'',
                fathername:'',
                mothername:'',
                email:'',
                phone:'',
                dob:'',
                gender:'',
                password:'',
                pasch:'',
                }
            ,
            formvalue:{},
            err:{},
            respon:false,
            reserror:{},
            validated: false,  
            boole : ""        
        }
        this.api = new Api();
        
    }
    componentWillUnmount = () =>{
 
        localStorage.removeItem('title');

    }
     handleSubmit = (event) => {
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setState({ validated: true })
 
        let valid = this.state.data
        let boole = 0;
        
    
        if( valid['first_name'] === '' ){
            
            boole = 1;
        }
        if(valid['last_name'] === ''){
            
            boole = 1;
        }
        if(valid["dob"] === ''){
            
            boole = 1;
        }

        if(valid["fathername"] === ''){
            
             boole = 1;
        }
        if(valid["gender"] === ''){
            
             boole = 1;
        }
        if(valid["mothername"] === ''){
            
             boole = 1;
        }
        if(valid["password"] === ''){
            
             boole = 1;
        }
        if(valid["relatype"] === ''){
            
             boole = 1;
        }
        if(valid["phone"] === ''){
            
             boole = 1;
        }

        if(valid["password"] === '' ){
        
             boole = 1;
            
        }
          
        if(valid["email"] === ''){
        
             boole = 1;

        }

        if(boole>0){

            // console.log('error');
        }
        else{

            // console.log('sucess');
            let res = this.api.apiCall("studentsubmit",this.state.data);
            res
            .then(res => {
                        if(res.respond === 'sucess'){
                            
                            this.setState({id:res.id});
                            localStorage.setItem('id', res.id);
                            // console.log(res.id)
                            this.setState({respon:true});                  
                            
                        }else{
                            this.setState({
                                reserror:res.respond
                            })
                            // console.log('fail');
                        }
                        // console.log(res );
                    })
            .catch(error => { console.error(error); return Promise.reject(error); });
        }

    }

    updateState = (e) =>{
      
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        // if( name === 'password'){

        //     const cryptr = new Cryptr('PassW@#');
        //     // let pass = passwordHash.generate(value);
        //     const encryptedString = cryptr.encrypt(value);
        //     // const decryptedString = cryptr.decrypt(encryptedString);
        //     data[name] = encryptedString;
        //     this.setState({data});
        //     // console.log(encryptedString);
        //     // console.log(decryptedString);
        // }
        // // let tes = passwordHash.isHashed(data[name])
        // console.log(data);
        // console.log(this.state.data );

    }
    

    render() { 
        // console.log(this.props);
        // console.log(this.state.login);
        let { title } = this.state.login;
        let { respon , validated } = this.state;
        if(respon){
            return(
                <Redirect to={{
                    pathname:'/student',
                }}
                />
            );
        }else{       
        return (     
            <React.Fragment>
                <div style={{marginTop:40,marginBottom:0,width:1100,backgroundColor:'#17aafa'}} className="container alert alert-primary text-center" role="alert">{title} Registraton</div>
                <div className="mx-auto" style={{width:1100,marginBottom:100,padding:30}}>
                      

                                                <Form      
                                                noValidate
                                                validated={validated}
                                                >
                                                <Form.Group as={Row} >
                                                    <Form.Label column sm="4">
                                                    First name
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="text"
                                                    name="first_name" 
                                                    onChange={this.updateState}
                                                    required/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                    <Form.Label column sm="4">
                                                    Last Name
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="text"
                                                    onChange={this.updateState} 
                                                    name="last_name" 
                                                    placeholder="" required/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">
                                                    Mobile No
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="number"
                                                    onChange={this.updateState} 
                                                    name="phone" 
                                                    placeholder="" required />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Form.Label column sm="4">
                                                    Email
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="email" 
                                                    name="email" 
                                                    onChange={this.updateState}
                                                    placeholder="" required />
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                <Form.Label column sm={4}>
                                                Relationship Type
                                                </Form.Label>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Son"
                                                 onChange={this.updateState} 
                                                 name="relatype" 
                                                 id="Radio1" 
                                                 value="Son"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Daughter"
                                                 onChange={this.updateState} 
                                                 name="relatype" 
                                                 id="Radio2" 
                                                 value="Daughter"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Wife"
                                                 onChange={this.updateState} 
                                                 name="relatype" 
                                                 id="Radio3" 
                                                 value="Wife"
                                                 required
                                                 />
                                                </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                    <Form.Label column sm="4">
                                                    Father/Husband Name
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="text"
                                                    name="fathername"                     
                                                    onChange={this.updateState}
                                                    placeholder="" 
                                                    required/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                    <Form.Label column sm="4">
                                                    Mother Name
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="text"
                                                    onChange={this.updateState}
                                                    name="mothername" 
                                                    placeholder=""
                                                    required/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                    <Form.Label column sm="4">
                                                    Date Of Birth
                                                    </Form.Label>
                                                    <Col sm="8">
                                                    <Form.Control type="date"
                                                    onChange={this.updateState}
                                                    name="dob" 
                                                    placeholder="" required
                                                    />
                                                    </Col>
                                                </Form.Group>
                                            
                                                <Form.Group as={Row} >
                                                <Form.Label column sm={4}>
                                                Gender
                                                </Form.Label>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Male"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios1" 
                                                 value="Male"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Female"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios2" 
                                                 value="Female"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Transgender"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios3" 
                                                 value="Transgender"
                                                 required
                                                 />
                                                </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                <Form.Label column sm="4">
                                                Password
                                                </Form.Label>
                                                <Col sm="8">
                                                <Form.Control type="password"
                                                onChange={this.updateState} 
                                                name="password" 
                                                autoComplete="false"
                                                placeholder="" required/>
                                                </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                <Form.Label column sm="4">
                                                confirm password 
                                                </Form.Label>
                                                <Col sm="8">
                                                <Form.Control type="password"
                                                onChange={this.updateState} 
                                                name="pasch" 
                                                autoComplete="false"
                                                placeholder="" required/>
                                                </Col>
                                            </Form.Group>
                                                            
                                        <Form.Group>
                                        <Col className="mx-auto" sm={2}>
                                            <Button variant="primary"
                                            onClick={this.handleSubmit}
                                            type="button">
                                                Submit
                                            </Button>
                                            </Col>
                                        </Form.Group>
                                        
                                            </Form>
                  
                </div>
            </React.Fragment>
         );
        }
    }
}
 
export default RegisterForm;