import React, { Component } from 'react';
import NavBar from './components/navbar'
import Footer from './components/footer';
import RegisterForm from './components/registration/registerform_student';
import RegisterCrop from './components/registration/registerform_crop';
import RegisterGove from './components/registration/registerform_Gover';
import RegisterHr from './components/registration/registerform_hr';
import RegisterCentre from './components/registration/registerform_centre';

// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();

class Register extends Component {
    constructor(props){
        super(props);
        this.state = { 
            data:''
        }
    }
    componentWillMount = () => {
        
        let datas = localStorage.getItem('title');
        let data = JSON.parse(datas);
        this.setState({data});
        // let logdata = Object.values(data);
        // console.log(data['id']);
    }
    render() { 
        // console.log(this.state.data);
        const { data } = this.state;
        // console.log(this.props);
        // console.log(this.props);
        // console.log(history);
        return ( 
            <React.Fragment>
                <NavBar />
                {this.registerFunc(data.id)}
                <Footer/>
            </React.Fragment>
         );
    }
    registerFunc = (log) => {
        if(log === 1){
            return(
                <RegisterForm params={this.state.data}/>
            );
        }else if(log === 2){
            return(
                <RegisterCentre params={this.state.data}/>
            );
        }else if(log === 3){
            return(
                <RegisterCrop params={this.state.data}/>
            );
        }else if(log === 4){
             return(
                <RegisterHr params={this.state.data}/>
            );
        }else{
            return(
                <RegisterGove params={this.state.data}/>
            );
        }
    }
    
}
 
export default Register;