import React, { Component } from 'react';


class Generaldetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            popup : {},
         }
    }
    render() { 
        // console.log(this.state.popup);
        // console.log(this.props.data.popdata);
        const { first_name , father_name , mobile_no ,mother_name ,last_name , dob , email_id } = this.props.data;
        return ( 
            <React.Fragment>
                <fieldset className="card p-4 m-2">
                <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">First Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" name="first_name" className="form-control" onChange={this.props.updateData} defaultValue={first_name} disabled={true}/>
                                                    </div>
                                            
                                                <label  className="col-sm-1 col-form-label">Last Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" name="last_name" className="form-control" onChange={this.props.updateData} defaultValue={last_name}/>
                                                    </div>
                                            </div>
                                            
                                            <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">Father/Husband Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" name="father_name" className="form-control" onChange={this.props.updateData} defaultValue={father_name}/>
                                                    </div>
                                            
                                                <label  className="col-sm-1 col-form-label">Mother Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" name="mother_name" className="form-control" onChange={this.props.updateData} defaultValue={mother_name}/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">Email</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" name="email_id" className="form-control" onChange={this.props.updateData} defaultValue={email_id}/>
                                                    </div>
                                            
                                                <label  className="col-sm-1 col-form-label">Phone Number</label>
                                                    <div className="col-sm-4">
                                                    <input type="number" name="mobile_no" className="form-control" onChange={this.props.updateData} defaultValue={mobile_no} disabled={true}/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">Date Of Birth</label>
                                                    <div className="col-sm-4">
                                                    <input type="date" name="dob" className="form-control" onChange={this.props.updateData} defaultValue={dob}/>
                                                    </div>
                                            </div>
                                            </fieldset>
            </React.Fragment>
         );
    }
}
 
export default Generaldetail;