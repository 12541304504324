import React, { Component } from 'react';
// import { Form , Col , InputGroup } from 'react-bootstrap'
import Api from '../../services/api/Api'


class Generaldetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popup:{
            },
            id:{id:this.props.data},
        }
        this.api = new Api();

    }
    componentWillMount = () => {

        let res = this.api.apiCall("getStudentData",this.state.id);
        res
        .then(res => {
            const popup = res.sdata;
            this.setState({ popup });
            // console.log(res);
          })
        .catch(error => { console.error(error); return Promise.reject(error); });
    }
    render() {
        // console.log(this.props.data);
        // console.log(this.state.id);
        // const validated = this.props.validated;
        const { first_name , father_name , mobile_no ,mother_name ,last_name , dob , email_id } = this.state.popup;
        return ( 
            <React.Fragment>
                <fieldset className="card p-4 m-2">
                                            <div className="form-group row">
                                                <label  className="col col-form-label">First Name</label>
                                                    <div className="col">
                                                    <input type="text" name="first_name" className="form-control" onChange={this.props.updateData} defaultValue={first_name} disabled={true} />
                                                    </div>
                                            
                                                <label  className="col col-form-label">Last Name</label>
                                                    <div className="col">
                                                    <input type="text" name="last_name" defaultValue={last_name} onChange={this.props.updateData} className="form-control" disabled={true}/>
                                                    </div>
                                            </div>
                                            
                                            <div className="form-group row">
                                                <label  className="col col-form-label">Father/Husband Name</label>
                                                    <div className="col">
                                                    <input type="text" name="father_name" defaultValue={father_name} onChange={this.props.updateData} className="form-control"/>
                                                    </div>
                                            
                                                <label  className="col col-form-label">Mother Name</label>
                                                    <div className="col">
                                                    <input type="text" name="mother_name" defaultValue={mother_name} onChange={this.props.updateData} className="form-control"/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                                <label  className="col col-form-label">Email</label>
                                                    <div className="col">
                                                    <input type="text" name="email_id" defaultValue={email_id} onChange={this.props.updateData} className="form-control" disabled={true}/>
                                                    </div>
                                            
                                                <label  className="col col-form-label">Phone Number</label>
                                                    <div className="col">
                                                    <input type="number" name="mobile_no" defaultValue={mobile_no} onChange={this.props.updateData} className="form-control" disabled={true}/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                                <label  className="col-sm-3 col-form-label">Date Of Birth</label>
                                                    <div className="col-sm-3">
                                                    <input type="date" name="dob" defaultValue={dob} onChange={this.props.updateData} className="form-control"/>
                                                    </div>
                                            </div>
                                                    {/* <Form
                                                        noValidate
                                                        validated={validated}
                                                       
                                                       >
                                                        <Form.Row>
                                                        <Form.Group as={Col} md="4">
                                                            <Form.Label>First name</Form.Label>
                                                            <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="First name"
                                                            
                                                            name="first_name" className="form-control" onChange={this.props.updateData} defaultValue={first_name}
                                                            disabled={true}
                                                            />
                                                           
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label>Last name</Form.Label>
                                                            <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Last name"
                                                            
                                                            name="last_name" defaultValue={last_name} onChange={this.props.updateData}
                                                            disabled={true}
                                                            />
                                                            
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4">
                                                            <Form.Label>Email Id</Form.Label>
                                                            <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="Email id"
                                                                aria-describedby="inputGroupPrepend"
                                                                required
                                                                name="email_id" defaultValue={email_id} onChange={this.props.updateData}
                                                                disabled={true}
                                                            />
                                    
                                                            </InputGroup>
                                                        </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                        <Form.Group as={Col} md="4">
                                                            <Form.Label>Father Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Father Name"
                                                            name="father_name" defaultValue={father_name} onChange={this.props.updateData}
                                                            required />
                                                          
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label>Mother Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Mother Name"
                                                            name="mother_name" defaultValue={mother_name} onChange={this.props.updateData}
                                                            required />
                                                            
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="4" >
                                                            <Form.Label>Date Of Birth</Form.Label>
                                                            <Form.Control  name="dob" defaultValue={dob} onChange={this.props.updateData} 
                                                            type="date" placeholder="Date Of Birth" required />
                                                            
                                                        </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                        
                                                        <Form.Group as={Col} md="4" >
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <Form.Control 
                                                            type="number"
                                                            placeholder="Mobile Number"
                                                            name="mobile_no" defaultValue={mobile_no} onChange={this.props.updateData}
                                                            required
                                                            disabled={true}
                                                        />
                                                        </Form.Group>
                                                        </Form.Row>
                                                        
                                                    </Form> */}
                                            </fieldset>
            </React.Fragment>
         );
    }
}
 
export default Generaldetail;