import React, { Component } from 'react';
import { withRouter }  from 'react-router-dom';
import { Form , Button , Col} from 'react-bootstrap';
import Api from '../../../services/api/Api'


class EditCentre extends Component {

    constructor(props){
        super(props);
        this.state = { 
            data:{},
            formvalue:{},
            err:{},
            respon:'',
            district:[],
            stated:[],
            country:[],
            id:this.props.id          
        }
        this.api = new Api();
        
    }
   
    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setState({ validated: true });
        // console.log('success');
      }

     componentWillMount(){
     
        // let corp_id = localStorage.getItem('id');
        // let ids = {"id":JSON.parse(corp_id)};
        // this.setState({id:ids});
        
        let  { id } = this.state;

        let res = this.api.apiCall("getdataCentre",id);
        res
        .then(result => {
            if(result.respon === 'sucess'){
                    this.setState({data:result.sdata})             
                }
                else{
                    this.setState({err:'Invalid Credencial'})
                }  
                // console.log(result);
            })
        .catch(error => { console.error(error); this.setState({data:''}) });

            let res1 = this.api.apiCallGet("state");
            res1
            .then(res => {
            
                this.setState({ stated:res.data });
           
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
        
            let res2 = this.api.apiCallGet("district");
            res2
            .then(res => {
                const district = res.data;
                this.setState({ district });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });

            let res3 = this.api.apiCallGet("country");
            res3
            .then(res => {
                const country = res.data;
                this.setState({ country });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });

    }

    updateState = (e) =>{

        // this.dataValidate();
        
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        // console.log(this.state.data );

    }
    
    render() { 
        // console.log(this.props);
        // console.log(this.state);
        // console.log(this.state.data[0].first_name);
        let { district , stated , country } = this.state
        let { centre_name, 
            // type_of_organization, 
            state_id,
            //  role_id,
              country_id, city_name,
            owner_name, distict_id,
              email_id, phone ,
            //    c_gender 
            } = this.state.data;        
        return (     
            <React.Fragment>

                <div style={{marginTop:60,marginBottom:0}} className="jumbotron">
                <div className="container alert alert-dark text-center" role="alert">
                {/* {this.state.name}  */}
                Edit Details</div>
                <div className="card">
                    <div className="card-body">
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} >
                            <Form.Label>Organisation Name</Form.Label>
                            <Form.Control 
                            type="text"
                            name="organization_name"
                            defaultValue={centre_name || ''} disabled={true}
                            // placeholder="Enter email" 
                            onChange={this.updateState}
                            />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label> Name</Form.Label>
                            <Form.Control
                            type="text"
                            name="c_Name"
                            defaultValue={owner_name || ''} disabled={true}
                            // placeholder="Password" 
                            onChange={this.updateState}

                            />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label> Mobile No</Form.Label>
                            <Form.Control 
                            type="number"
                            name="Mobile"
                            defaultValue={phone || ''} disabled={true}
                            // placeholder="1234 Main St" 
                            onChange={this.updateState}

                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label> Email</Form.Label>
                            <Form.Control 
                            type="email"
                            name="Email"
                            defaultValue={email_id || ''} disabled={true}
                            // placeholder="1234 Main St" 
                            onChange={this.updateState}

                            />
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>

                        {/* <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Organisation Address</Form.Label>
                            <Form.Control 
                            defaultValue={address || ''}
                            name="Address"
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Area</Form.Label>
                            <Form.Control 
                            defaultValue={area || ''}
                            name="Area"
                            // placeholder="Apartment, studio, or floor"
                             />
                        </Form.Group> */}
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                            defaultValue={city_name || ''}
                            name="City"
                            onChange={this.updateState}

                            //  placeholder="Apartment, studio, or floor"
                              />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                            defaultValue={city_name || ''}
                            onChange={this.updateState}

                            name="City"
                            //  placeholder="Apartment, studio, or floor"
                              />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Type of Organization</Form.Label>
                            <Form.Control
                            defaultValue={city_name || ''}
                            onChange={this.updateState}

                            name="City"
                            //  placeholder="Apartment, studio, or floor"
                              />
                        </Form.Group>
                        </Form.Row>
                        <Form.Row>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                            name="country_id"
                            as="select"
                            value={country_id}
                            onChange={this.updateState}

                            >

                                <option>..choose..</option>
                                { country.map((country,ind) => <option key={ind} value={country[0]}>{country[2]}</option>
                            )}
                            </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                            name="state_id"
                            as="select"
                            value={state_id}
                            onChange={this.updateState}

                            >

                                 <option>..choose..</option>
                                 { stated.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                            </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>District</Form.Label>
                            <Form.Control 
                            name="distict_id"
                            as="select"
                            value={distict_id}
                            onChange={this.updateState}

                            >
                               <option>..choose..</option>
                                {  district.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                            )}
                            
                            </Form.Control>
                            </Form.Group>

                        </Form.Row>

                        <Button variant="primary" onClick={this.handleSubmit} type="button">
                            Save
                        </Button>
                        </Form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(EditCentre);