import React, { Component } from 'react';
import { Form , Row , Col , Pagination} from 'react-bootstrap';
// import axios from 'axios';
import Api from '../../../services/api/Api'

// import Pagination from "react-js-pagination";

class ShortList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{id:this.props.id.id,gender:'Male'},
            filted:[],
            hits:[],
            stated:[],
            // url:this.props.url,
            alt:true,
            PageLimit:10, 
            initiallimit:0,
            page:1,
            range:"",
            pagerangNext:true,
            pagerangPrev:true,
            // subset:[] 
         }
        this.api = new Api();

    }
    onChangeData = (e) =>{

         
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});

        let res = this.api.apiCall("CentreList",this.state.data);
        res
        .then(result => {
            if(result.respon === 'sucess'){

                    this.setState({ filted:result.filter });
                    this.setState({ range:result.filter.length });
                    this.setState({alt:true})
                    this.setState({pagerangNext:false,pagerangPrev:false})
                    this.setState({initiallimit:0,PageLimit:10,page:1})                    
                    // console.log(result.data);
                    }else{
                    this.setState({alt:false})
                    }
                // console.log(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    
    // componentDidMount = () => {

        // let res = this.api.apiCallGet("state");
        // res
        // .then(result => {
        //                 this.setState({ stated:result.data });
        //                 console.error(result);
        //     })
        // .catch(error => { console.error(error); return Promise.reject(error); });

    // }
    tableData = (filted) =>{
        const alt = this.state.alt;

        const { 
            initiallimit , 
             PageLimit,
             page,
             pagerangNext,
             pagerangPrev,
             range
            } = this.state

        let pagerangNexts = pagerangNext;
        const subset = filted.slice(initiallimit, PageLimit);

        const ranges = ( range === '' ) ? '0' : range;
        if(subset.length<10){
            // console.log(subset);
            pagerangNexts = true;
        }
        if(alt){
                
                return(
                    <tbody>
                    {subset.map((data,index) =>
                    // if(index){
                    <tr key={index}>
                        <td>{index + (initiallimit + 1) }</td>
                        <td>{data[3]}</td>
                        <td>{data[7]}</td>   
                        <td>{data[5]}</td>
                        <td>{data[6]}</td>
                        <td>{data[2]}</td>
                        <td>{data[1]}</td>
                    </tr>
                    // }
                    )}
                    <tr className="p-4 m-2" style={{paddingTop:40}}>
                        <td colSpan={3}></td>
                        <td colSpan={3}>
                            <Pagination style={{paddingTop:20}}>
                                <Pagination.Prev onClick={this.movePrev} disabled={pagerangPrev}/>
                                <Pagination.Item active>{page}</Pagination.Item>
                                <Pagination.Next onClick={this.moveNext} disabled={pagerangNexts}/>
                                <p className="text-primary align-middle h6 p-2">
                                &nbsp; Total File Count &nbsp;
                                </p>
                                <Pagination.Item >{ranges}</Pagination.Item>          
                            </Pagination>
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                );
        }else{
                return(
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="5">No Data Founded</td>
                    </tr>
                </tbody>
                );
        }
    }

    movePrev = () =>{

        const { initiallimit } = this.state

        if(initiallimit !== 0){
            const initiallimit = this.state.initiallimit - 10;
            const PageLimit = this.state.PageLimit - 10;
            const page = this.state.page - 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }
    moveNext = () =>{

        const { filted } = this.state

        if(filted !== '' ){
        const initiallimit = this.state.initiallimit + 10;
        const PageLimit = this.state.PageLimit + 10;
        const page = this.state.page + 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }

    render() { 
        // console.log(this.state);
        let { 
            filted ,
             } = this.state;

        return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:10,padding:4}}>
                    <div className="jumbotron-fluid">
                    <div className="pos-f-t">
                            <div className="collapse show" id="navbarToggleExternalContent">
                                <div className="bg-light p-4">
                                    <div className="jumbotron-fluid">
                                    <Form className="p-4">
                                        <Form.Group as={Row} controlId="formGridState">
                                                <Form.Label className="align-middle">Batch ID</Form.Label>
                                                <Col>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    onChange={this.onChangeData}
                                                    name="batch_id"
                                                    placeholder="Batch id"
                                                    />   
                                                </Col>
                                                <Form.Label className="align-middle">Programs</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="programs" onChange={this.onChangeData}>
                                                    <option value="">Choose...</option>
                                                    <option value="UPSDM">UPSDM  </option>
                                                    {/* <option value="UPSDM">PMKVY - 2.0	</option> */}
                                                    {/* <option value="Apparel, Made-ups & Home Furnishing">Apparel, Made-ups & Home Furnishing</option>
                                                    <option value="Banking & Accounting">Banking & Accounting</option>
                                                    <option value="Electronics">PMKVY - 2.0	</option>
                                                    <option value="Electrical">Electrical</option>
                                                    <option value="Garment Making">Garment Making</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Telecom">Telecom </option>
                                                    <option value="Capital Goods">Capital Goods</option>
                                                    <option value="Tourism and Hospitality">Tourism and Hospitality</option> */}
                                                </Form.Control>
                                                </Col>
                                            <Form.Label className="align-middle">Gender</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="gender" onChange={this.onChangeData}>
                                                    <option value="">..Choose...</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Transgender">Transgender</option>
                                                </Form.Control>
                                                </Col>
                                        </Form.Group>
                                    </Form>
                                    </div>
                                </div>
                            </div>
                            <nav className="navbar navbar-dark bg-dark ">
                            <span className="p-2 text-light font-weight-bolder"> Student List</span>
                                <div className="float-right">
                                    <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    Filter <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </nav>
                    </div>
                        <table className="table table-sm">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Candidate Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">State</th>
                                <th scope="col">District</th>
                                <th scope="col">Programs</th>
                                <th scope="col">Batch ID</th>
                                {/* <th scope="col">Mark</th> */}
                                </tr>
                            </thead>
                            {this.tableData(filted)}
                        </table>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default ShortList;