import React, { Component } from 'react';
import { Form , Col , Button , InputGroup  } from 'react-bootstrap'
// import axios from 'axios';
import Api from '../../../services/api/Api'


class Post extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            validated: false,
            data:{
                ind_id:'',
                Category: "",
                Email_Id: "",
                Internship_Post_Valid: "",
                Internship_for: "",
                Internship_title: "",
                MaxStipend: "",
                MinStipend: "",
                Month: "",
                Name: "",
                Resume: "",
                Skill: "",
                Take_Away: "",
                Week: "",
                Who_Can_Apply: "",
                location: "",
                no_candidated_req: "",
            },
            stated:[],
            edu:[],
            move:false,
            id:{}
         }
        this.api = new Api();

    }
    componentWillMount = () => {
        
        let corp_id = localStorage.getItem('id');
        let ids = {"ind_id":JSON.parse(corp_id)};
        this.setState({data:ids});

        let res = this.api.apiCallGet("state",'');
                             
        res
        .then(result => { 
            
                            this.setState({ stated:result.data });
                            // console.log(result.data)
            
          })
        .catch(error => { console.error(error); return Promise.reject(error); });
            
        let res2 = this.api.apiCallGet("education",'');
        
        res2
        .then(results => {
            this.setState({ edu:results.data });
           
            })
        .catch(error => { console.error(error); return Promise.reject(error); });
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setState({ validated: true })
        
        let boole = 0;
        
        let valid = this.state.data;
            
        if( valid['Category'] === '' ){
            
            boole = 1;
        }
        if(valid['Email_Id'] === ''){
            
            boole = 1;
        }
        if(valid["Internship_Post_Valid"] === ''){
            
            boole = 1;
        }

        if(valid["Internship_for"] === ''){
            
             boole = 1;
        }
        if(valid["Internship_title"] === ''){
            
             boole = 1;
        }
        if(valid["MaxStipend"] === ''){
            
             boole = 1;
        }
        if(valid["MinStipend"] === ''){
            
             boole = 1;
        }
        if(valid["Month"] === ''){
            
             boole = 1;
        }
        if(valid["Name"] === ''){
            
             boole = 1;
        }
          
        if(valid["Resume"] === ''){
        
             boole = 1;

        } if( valid["Take_Away"] === '' ){
        
            boole = 1;
       
       }
        if(valid["Skill"] === '' ){
        
             boole = 1;
        
        }
        if(valid["Week"] === ''){
        
            boole = 1;

       } if( valid["no_candidated_req"] === '' ){
       
           boole = 1;
      
      }
       if(valid["location"] === '' ){
       
            boole = 1;
       
       }
    
        // console.log(boole);

        if(boole>0){

            // console.log('error');
        }
        else{


        let res2 = this.api.apiCall("postcrop",this.state.data);
        
        res2
        .then(res => {
            if(res.respond === 'sucess'){   
                this.setState({move:true,id:res.id});    
            }else{
                this.setState({err:'Invalid Credencial'})
                // console.log('fail');
            }  
            // console.log(res.data );
        })
        .catch(error => { console.error(error); return Promise.reject(error); });
            
        }
      }

    onChangeData = (e) =>{

        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        // console.log(data);
    }
    newPost = () =>{
        this.setState({
            move:false
            // validated: false
        })
        // this.refs.formpost.reset();  
        // document.getElementById("formpost").reset();    

    }
    render() { 
        const { validated , stated , edu , move } = this.state;
        // console.log(this.state)
        if(move){
                return(
                    <div className="container-fluid" style={{marginTop:70}}>
                        <div className="jumbotron">
                            <div className="card">
                                <div className="card-body">
                                    <span className="text-success">Your Internship Successfull posted</span>
                                </div>
                            </div>
                            <div className="card-body">
                            <button className="btn btn-success" onClick={this.newPost}> New Post </button>
                            </div>
                        </div>
                    </div>   
                );
        }else{
        return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:70}}>
                    <div className="jumbotron">
                        <div className="card">
                            <div className="card-header">
                                <h6 className="h5 m-auto">Internship Post</h6>
                            </div>
                            <div className="card-body">
                            <Form
                                    id="formpost"
                                    noValidate
                                    validated={validated}
                                >
                                    <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                                        <Form.Label>Internship Title</Form.Label>
                                        <Form.Control
                                        required
                                        type="text"
                                        placeholder="Internship title"
                                        // defaultValue=""
                                        name="Internship_title"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter the Internship title.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                        required
                                        type="text"
                                        placeholder="Name"
                                        // defaultValue="Otto"
                                        name="Name"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">Please enter the Name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                        <Form.Label>Email Id</Form.Label>
                                        <InputGroup>
                                        {/* <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        </InputGroup.Prepend> */}
                                        <Form.Control
                                            type="email"
                                            placeholder="Email id"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="Email_Id"
                                            onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a Email.
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Internship For</Form.Label>
                                            <Form.Control as="select"
                                             name="Internship_for"
                                             onChange={this.onChangeData}>
                                             <option>..choose..</option>
                                 { edu.map((edu,ind) => <option key={ind} value={edu[0]}>{edu[1]}</option>
                            )}
                                            </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                                            <Form.Label> Category</Form.Label>
                                            <Form.Control as="select"
                                            name="Category"
                                            onChange={this.onChangeData}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1" >
                                            <Form.Label>location</Form.Label>
                                            <Form.Control as="select"
                                            name="location"
                                            onChange={this.onChangeData}>
                                            <option>..choose..</option>
                                 { stated.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                                            </Form.Control>
                                    </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                                            <Form.Label>(Duration)Month</Form.Label>
                                            <Form.Control as="select" name="Month"
                                     onChange={this.onChangeData}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                                            <Form.Label> (Duration)Week	</Form.Label>
                                            <Form.Control as="select"
                                                name="Week"
                                                onChange={this.onChangeData}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Key Skill</Form.Label>
                                            <Form.Control as="select"
                                            name="Skill"
                                            onChange={this.onChangeData}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            </Form.Control>
                                    </Form.Group>
                                    </Form.Row>
                                    <Form.Group>
                                    <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01"
                                     name="Stipend"
                                     onChange={this.onChangeData}>
                                        <Form.Label>Max Stipend</Form.Label>
                                        <Form.Control
                                        required
                                        type="number"
                                        // placeholder="First name"
                                        // defaultValue="Mark"
                                        name="MaxStipend"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">Enter Max Stipend</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Min Stipend	</Form.Label>
                                        <Form.Control
                                        required
                                        type="number"
                                        // placeholder="Last name"
                                        // defaultValue="Otto"
                                        name="MinStipend"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">Enter Min Stipend</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                        <Form.Label>No. Of Candidated Required</Form.Label>
                                        <InputGroup>
                                        {/* <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        </InputGroup.Prepend> */}
                                        <Form.Control
                                            type="number"
                                            // placeholder="Username"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="no_candidated_req"
                                            onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a No. Of Candidated Required.
                                        </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                    <Form.Group as={Col} md="4" controlId="validationCustom01"
                                     >
                                        <Form.Label>Internship Post Valid Till (DD/MM/YYYY)	</Form.Label>
                                        <Form.Control
                                        required
                                        type="date"
                                        // placeholder="First name"
                                        // defaultValue="Mark"
                                        name="Internship_Post_Valid"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback type="invalid">Enter the Internship Post Valid Date</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                                        <Form.Label>Who Can Apply</Form.Label>
                                        <Form.Control
                                        required
                                        type="textarea"
                                        // placeholder="Last name"
                                        // defaultValue="Otto"
                                        name="Who_Can_Apply"
                                        onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                                        <Form.Label>Take Away</Form.Label>
                                        <InputGroup>
                                        {/* <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        </InputGroup.Prepend> */}
                                        <Form.Control
                                            type="textarea"
                                            // placeholder="Username"
                                            aria-describedby="inputGroupPrepend"
                                            required
                                            name="Take_Away"
                                            onChange={this.onChangeData}
                                        />
                                        <Form.Control.Feedback>
                                                    Look good!         </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    </Form.Row>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Row>
                                            <Col sm={2}>
                                            <Form.Label>
                                                Resume Required
                                            </Form.Label>
                                            </Col>
                                            <Col sm={1}>
                                            <Form.Check
                                            type="radio"
                                            label="Yes"
                                            value="Yes"
                                            name="Resume"
                                            id="res2"
                                            onChange={this.onChangeData}
                                        required
                                        feedback="You must click between submitting."
                                            />
                                            </Col>
                                            <Col sm={1}>
                                            <Form.Check
                                            type="radio"
                                            label="no"
                                            value="no"
                                            name="Resume"
                                            id="res1"
                                            onChange={this.onChangeData}
                                        required
                                        feedback="You must click between submitting."
                                            />
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                    <Button type="button" onClick={e => this.handleSubmit(e)} >Post Internship</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
         );
    }
    }
}
 
export default Post;