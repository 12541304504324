import React, { Component } from 'react';

class Applied extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        // console.log('applied');
        return ( 
            <React.Fragment>
                <React.Fragment>
                     <div className="container-fluid" style={{marginTop:60}}>
                    
                    <div className="jumbotron">
                        
                        <p className="font-weight-bold">Internship Details</p>
                        <p>Student did't apply any Internship</p>
                        </div></div>
                </React.Fragment>
            </React.Fragment>
         );
    }
}
 
export default Applied;