import React, { Component } from 'react';
import Api from '../../../services/api/Api'


class Personaldetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lang:[],
            // pdata:{},
            // url:'http://192.168.1.21/bskill/src/db.php',
            popup:{},
            data:{}
         }
        this.api = new Api();

    }

    componentDidMount = () =>{

        let stud_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(stud_id)};
        // this.setState({update:{"id":stud_id}});

        let res1 = this.api.apiCallGet("language");
        res1
        .then(res => {
            const lang = res.data;
            this.setState({ lang });
          //   console.log(lang);
          })
        .catch(error => { console.error(error); return Promise.reject(error); });
       
        this.setState({popup:this.props.data.popdata});

                let res = this.api.apiCall("getdata",ids);
                res
                .then(res => {
                    this.setState({ data:res.sdata});
                    // console.log(res.sdata);
                })
          .catch(error => { console.error(error); return Promise.reject(error); });

    }
    render() { 
        let k = this.state.lang;
        // console.log(this.state.popup);
        let {  mother_toug , name_change , iynchange , a_name_omark , religion , marital_status } = this.props.data;
        // mother_toug = mother_toug === null ? "" : mother_toug;
        // console.log(mother_toug);
        return ( 
            <React.Fragment>
                <fieldset className="card p-4 m-2">
                <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">Applicant Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" onChange={this.props.updateData} className="form-control" name="aname"  defaultValue={a_name_omark} placeholder="Applicant Name as per marksheet"/>
                                                    </div>
                                            
                                                <label  className="col-sm-2 col-form-label">Religion Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text" onChange={this.props.updateData} name="regname"  defaultValue={religion} className="form-control"/>
                                                    </div>
                                            </div>
                                            
                                            <div className="form-group row">
                                                <label  className="col-sm-3 col-form-label">Is your name changed after qualification?</label>
                                                    <div className="col-sm-3">
                                                    <select className="custom-select" onChange={this.props.updateData}  value={iynchange || ''} name="yncqual" id="inputGroupSelect01">
                                                        <option defaultValue>Choose...</option>
                                                        <option value="1">Yes</option>
                                                        <option value="2">No</option>
                                                    </select>
                                                    </div>
                                            
                                                <label  className="col-sm-2 col-form-label">Changed Name</label>
                                                    <div className="col-sm-4">
                                                    <input type="text"  name="changed" onChange={this.props.updateData}  defaultValue={name_change} className="form-control"/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                                <label  className="col-sm-2 col-form-label">Mother Tongue</label>
                                                    <div className="col-sm-4">
                                                    <select className="custom-select" onChange={this.props.updateData}  value={mother_toug} name="mtongue" id="inputGroupSelect01">
                                                        <option >Choose...</option>
                                                        {  k.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                                                        )}
                                                    </select>
                                                    </div>
                                            
                                                <label  className="col-sm-2 col-form-label"> Alternative Number</label>
                                                    <div className="col-sm-4">
                                                    <input type="number" name="anumber" onChange={this.props.updateData}  className="form-control"/>
                                                    </div>
                                            </div>
                                            <div className="form-group row">
                                            <label  className="col-sm-2 col-form-label">Marital Status</label>
                                            <div className="col-sm-6">
                                            <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" onChange={this.props.updateData} defaultChecked={(marital_status === 'Married')?true:false} name="mstatus" id="exampleRadios1" value="Married"/>
                                                    <label className="form-check-label">
                                                    Married
                                                    </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" onChange={this.props.updateData}  defaultChecked={(marital_status === 'Unmarried')? true:false}  name="mstatus" id="exampleRadios2" value="Unmarried"/>
                                                    <label className="form-check-label" >
                                                    Unmarried
                                                    </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="radio" onChange={this.props.updateData}  defaultChecked={(marital_status === 'Widow')? true:false} name="mstatus" id="exampleRadios3" value="Widow"/>
                                                    <label className="form-check-label" >
                                                    Widow
                                                    </label>
                                            </div>
                                            </div>
                                            </div>
                                            </fieldset>
                                            {/* <button className="btn btn-primary btn-md m-3" onClick={this.props.moveNext}>Next</button>
                                            <button className="btn btn-primary btn-md" onClick={this.props.moveBack}>back</button> */}

            </React.Fragment>
         );
    }
}
// Personaldetail.propTypes = {
//     aname: PropTypes.string,
//     mtongue: PropTypes.string,
//     // aname: PropTypes.string,
//     // aname: PropTypes.string,

//   }; 


export default Personaldetail;