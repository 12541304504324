import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/broadline_logo.png'

class NavBarapp extends Component {
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         // data:this.props,
    //     }
    // }
    render() { 
        // console.log(this.state);
        return ( 
            <React.Fragment>
                <nav className="navbar navbar-light bg-light">
                    <span className="md h1" style={{fontFamily:"jokerman"}}>BROADSKILLS</span>
                    <div className="float-none">
                        <img src={logo} className="" style={{width:'auto',height:'auto',padding:7}} alt="Broadline"/>                    
                    </div>
                </nav>
                <nav className="navbar navbar-dark " >
                    <span className="navbar-brand md h1" style={{backgroundColor:'#17aafa',fontFamily:"jokerman"}}></span>
                        <ul className="nav justify-content-end">
                            {/* <li className="nav-item" style={{paddingRight:10}}>
                                <Link className="btn btn-outline-primary my-2 my-sm-0" style={{color:'#17aafa',borderColor:'#17aafa'}} to='/'>Home</Link>  
                            </li> */}
                            <li className="nav-item">
                                <Link className="btn btn-primary my-2 my-sm-0" style={{backgroundColor:'#17aafa',borderColor:'#17aafa'}} to='/login' >Login</Link>
                            </li>
                        </ul>
                </nav>
            </React.Fragment>
         );
    }
}
 
export default NavBarapp;