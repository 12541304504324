import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faBars, 
    //  faUserCircle ,
    //   faBell , 
      faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import './dash.css';
import { withRouter , 
    Link ,
    //  BrowserRouter as Router, 
     HashRouter  as Router,
    // Switch , 
    // Router,
    // NavLink,
    Route 
}from 'react-router-dom';
import Progress from './progress';
import RegisterCrop from './registerform_crop';
import Post from './post';
import CorpHome from './home';
// import axios from 'axios';
import ManageCorp from './manage';
import ShortListing from './shortlisting';
// import ShortListedStudent from './shortlisted';
// import Applied from './applied';
// import PostedApplication from './postapplied';
import { createBrowserHistory } from "history";
import Api from '../../../services/api/Api'

const history = createBrowserHistory()


class Navdashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            i:"",
            toggle:"toggled",
            data:{},
            // url:'/db',
         }
        this.api = new Api();
       

    }
    toggle = () =>{
        
        const tg = this.state.toggle;
        if(tg === "toggled"){
            this.setState({toggle:""});
        }else{
            this.setState({toggle:"toggled"});
        }
        
    }
    componentWillMount() {

        // let corp_id = localStorage.getItem('id');
        // let ids = {"id":JSON.parse(corp_id)};
        // this.setState({id:ids});
        // this.setState({i:this.props.id});
        // let { i } = this.state
        // console.log(ids);

        let res = this.api.apiCall("getdataCorp",this.props.id);
        res
        .then(result => {
              this.setState({ data:result.sdata});
                // console.log(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    
     render() { 
        let {
             organization_name , 
         id } = this.state.data;
        let { url } = this.state;
        // console.log(this.props);
        // console.log(this.state.i);

        return ( 
            
    <Router browserHistory={history} basename="/corp/dashboard/">
            <React.Fragment>
                <nav className="navbar navbar-expand navbar-dark fixed-top" style={{backgroundColor:'#17aafa'}}> 
                    <button onClick={this.toggle} style={{backgroundColor:'#17aafa'}} id="menu-toggle" className="navbar-brand btn">
                        <FontAwesomeIcon className="fa-2x" icon={faBars}/> 
                    </button>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample02" aria-controls="navbarsExample02" aria-expanded="false" aria-label="Toggle navigation"></button>
                    <div className="collapse navbar-collapse" id="navbarsExample02">
                        <h1 className="my-2 my-md-0" style={{color:'white'}}>
                        
                        </h1>
                    </div>
                        {/* <button className="btn  my-2 my-sm-0"  ><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faBell}/> <span className="badge badge-light"></span></button> */}
                        {/* <button className="btn btn-primary my-2 my-sm-0"><FontAwesomeIcon style={{height:30,width:30}} icon={faUserCircle}/></button> */}
                        <button onClick={this.logout} className="btn my-2 my-sm-0" title="Logout"><FontAwesomeIcon className="far" style={{height:30,width:30}} icon={faSignOutAlt}/></button>
                </nav>
        <div id="wrapper" className={this.state.toggle} >
            
            <div id="sidebar-wrapper" style={{backgroundColor:'#b9bbbe',color:'black'}} >
                    <div className="sidebar-nav nav flex-column nav-tab"  id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <ul className="sidebar-nav">
                    <li><h6 className="p-3 h4" style={{marginTop:80}}> { organization_name || '' } </h6></li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/",
                     
                    }} >Home</Link></li> 
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/edit",
                
                    }} >Edit Profile</Link> </li>
                    <li><Link className="nav-link text-dark" to={{
                        pathname:"/manage",
                  
                    }} >Manager</Link> </li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/post",
    
                    }} >Post intership</Link> </li>
                    <li><Link className="nav-link text-muted disabled" to={{
                        pathname:"/payment",

                    }} >Payment</Link></li>
                    <li><Link   className="nav-link text-dark" to={{
                        pathname:"/shortlist",
         
                    }} >Shortlist</Link> </li>
                    <li><Link  className="nav-link text-dark" to={{
                        pathname:"/Profile",
                
                    }} >Profile progress</Link> </li>
                </ul>
                    </div>
            </div> 
            
            <div id="page-content-wrapper">
                <div className="container-fluid">
                  
                            <Route path="/" exact={true} strict render={ (props) =>{
                                return(
                                    <CorpHome id={id} name={organization_name}/>                   
                                        );
                                }
                                }/>
                            <Route path="/edit" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <RegisterCrop id={id} url={url}/>
                                        );
                                }
                                }/>
                            <Route path="/post" exact={false}  strict render={ (props) =>{
                                    return(
                                        
                                        <Post id={id} url={url}/>
                                        );
                                }
                                }/>
                            <Route path="/profile" exact={false} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                        
                                        <Progress id={id}/>
                                        );
                                }
                                }/>
                                <Route path="/shortlist" exact={false} strict render={ (props) =>{
                                    return(
                                        
                                        <ShortListing  id={id} url={url}/>
                                        );
                                }
                                }/>
                                <Route path="/manage" exact={true} strict render={ (props) =>{
                                    // console.log(props);
                                    return(
                                            <ManageCorp id={id} url={url}/>
                                        );
                                }
                                }/>
                                
              </div>
            </div> 
        </div> 
    
            
    </React.Fragment>
    </Router>

         );
    }
    logout = () =>{
        
        localStorage.clear();
        window.location.href = '/';
    }
}
 
export default withRouter(Navdashboard);