import React, { Component } from 'react';
import Navdashboard from './nav';
// import Footer from '../footer';
// import $ from 'jquery';
// import LayoutTest from './navtest';
// import "";

class Studentdash extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         }
    }
    render() { 
        // console.log(this.props)
        return ( 
            <React.Fragment>
                <Navdashboard id={this.props}/>
                {/* <Footer/> */}
            </React.Fragment>
         );
    }
}
 
export default Studentdash;