import React, { Component } from 'react';
import { 
    // Form , Row , Col ,
Pagination
} from 'react-bootstrap';
import Api from '../../../services/api/Api'


class IndusPost extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{gender:'Male'},
            filted:[],
            hits:[],
            stated:[],
            id:{id:this.props.id},
            alt:true,
            PageLimit:10, 
            initiallimit:0,
            page:1,
            range:"",
            pagerangNext:false,
            pagerangPrev:false,
             
         }
        this.api = new Api();

    }
    onChangeData = (e) =>{

         
        // let data = this.state.data;
        // let name = e.target.name;
        // let value = e.target.value;
            
        // data[name] = value;

        // this.setState({data});

        const { id } = this.state

        let res = this.api.apiCall("InternshipList",id);
        res
        .then(result => {
            if(result.respon === 'sucess'){

                    this.setState({ filted:result.filter });
                    this.setState({ range:result.filter.length });
                    // console.log(result.data);
                    this.setState({alt:true})
        
                    }else{
                    this.setState({alt:false})
                    }
                // console.log(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    
    movePrev = () =>{

        const { initiallimit } = this.state

        if(initiallimit !== 0){
            const initiallimit = this.state.initiallimit - 10;
            const PageLimit = this.state.PageLimit - 10;
            const page = this.state.page - 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }
    moveNext = () =>{

        const { filted } = this.state

        if(filted !== '' ){
        const initiallimit = this.state.initiallimit + 10;
        const PageLimit = this.state.PageLimit + 10;
        const page = this.state.page + 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }

    componentDidMount = () => {

    //     let res = this.api.apiCallGet("state");
    //     res
    //     .then(result => {
    //                     this.setState({ stated:result.data });
    //                     console.error(result);
    //         })
    //     .catch(error => { console.error(error); return Promise.reject(error); });

    const { id } = this.state

    let res = this.api.apiCall("InternshipList",id);
    res
    .then(result => {
        if(result.respon === 'sucess'){

                this.setState({ filted:result.sdata });
                this.setState({ range:result.sdata.length });
                // console.log(result.data);
                this.setState({alt:true})
    
                }else{
                this.setState({alt:false})
                }
            // console.log(result);
        })
    .catch(error => { console.error(error); return Promise.reject(error); });

    }
    tableData = (filted) =>{
        const alt = this.state.alt;

        const { 
            initiallimit , 
             PageLimit,
             page,
             pagerangNext,
             pagerangPrev,
             range
            } = this.state

        let pagerangNexts = pagerangNext;
        const subset = filted.slice(initiallimit, PageLimit);

        const ranges = ( range === '' ) ? '0' : range;
        if(subset.length<10){
            // console.log(subset);
            pagerangNexts = true;
        }


        if(alt){
            return(
                <tbody>
                {subset.map((data,index) =>
                 <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data[5]}</td>
                    <td>{data[0]}</td>   
                    <td>{data[1]}</td>
                    <td>{data[4]}</td>
                    <td>{data[6]}</td>
                    <td>{data[3]}</td> 
                    <td>{data[2]}</td> 
                </tr>)}
                <tr className="p-4 m-2">
                        <td colSpan={3}></td>
                        <td colSpan={4}>
                            <Pagination style={{paddingTop:20}}>
                                <Pagination.Prev onClick={this.movePrev} disabled={pagerangPrev}/>
                                <Pagination.Item active>{page}</Pagination.Item>
                                <Pagination.Next onClick={this.moveNext} disabled={pagerangNexts}/>
                                <p className="text-primary align-middle h6 p-2">
                                &nbsp; Total File Count &nbsp;
                                </p>
                                <Pagination.Item >{ranges}</Pagination.Item>          
                            </Pagination>
                        </td>
                        <td></td>
                </tr>
            </tbody>
            );
        }else{
                return(
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="5">No Data Founded</td>
                    </tr>
                </tbody>
                );
        }
    }
    
    render() { 
        // console.log(this.state.filted);
        let { 
            filted ,
            //  stated
             } = this.state;
        return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:70,padding:4}}>
                    <div className="jumbotron-fluid">
                    <div className="pos-f-t">
                            <div className="collapse show" id="navbarToggleExternalContent">
                                <div className="bg-light p-4">
                                    <div className="jumbotron-fluid">
                                    
                                    </div>
                                </div>
                            </div>
                            <nav className="navbar navbar-dark bg-dark ">
                            <span className="p-2 text-light font-weight-bolder"> Internship Post List</span>
                                <div className="float-right">
                                    {/* <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    Filter <span className="navbar-toggler-icon"></span>
                                    </button> */}
                                </div>
                            </nav>
                    </div>
                        <table className="table table-sm">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Industial Name</th>
                                <th scope="col">Internship title</th>
                                <th scope="col">Name</th>
                                <th scope="col">Post Date</th>
                                <th scope="col">State</th>
                                <th scope="col">Valid Date</th>
                                {/* <th scope="col">Job Role</th> */}
                                <th scope="col">Number of Openings</th>
                                </tr>
                            </thead>
                            {this.tableData(filted)}
                        </table>
                        <div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default IndusPost;