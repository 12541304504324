import axios from 'axios';


class Api{
    
     url = process.env.PUBLIC_URL+"/database/db.php";

    // url = "http://localhost/database/db.php";

    token = "xjfjs365JGSDgk76sa8djg8";

    apiCall(type,data){

    let respond  = axios({

                        method: 'post',
                        url: this.url+"?type="+type,
                        data: data,
                        config: { headers: {'Content-Type': 'application/json'}}
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 404) {
                                return `\u2014`;
                            }
                            if (error.response.status === 500) {
                                return `\u2014`;
                            }
                        }
                    })
                     
                return respond 
}

apiCallGet(type){

    let responds  = axios({

                        method: 'post',
                        url: this.url+"?type="+type,
                        config: { headers: {'Content-Type': 'application/json',"Authorization" : "sadad"}}
                    })
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.status === 404) {
                                return `\u2014`;
                            }
                            if (error.response.status === 500) {
                                return `\u2014`;
                            }
                        }
                    })                     

    return responds;
}


}

export default Api;