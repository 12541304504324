import React, { Component } from 'react';
// import logo from '../images/broadline_logo.png'

class Content extends Component {

    render() { 
        return ( 
            <React.Fragment>
                <div className="navbar navbar-dark" style={{marginBottom:0,backgroundColor:"#17aafa ",color:'white'}} >
                    <div className="container p-4" style={{textAlign:'center'}}>
                        <h1 className="h1 mx-auto " >Broadline Skill Development</h1>
                        <p className="h5 lead">In order to enhance employability, self-employability, digital freelancing and entrepreneurship in the context of 21st century’s emerging challenges and opportunities, the next generation of  students need to know the essentials in IT (Information Technology) and Occupational Spoken English.</p>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Content;