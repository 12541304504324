import React, { Component } from 'react';
import LoginPage from './components/formlogin'
import NavBar from './components/navbar';
import Footer from './components/footer';
import 'bootstrap/dist/css/bootstrap.css';


class Login extends Component {
    // state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <NavBar/>
                <LoginPage />
                <Footer/>
            </React.Fragment>
         );
    }
}
 
export default Login;
