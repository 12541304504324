import React, { Component } from 'react';
import { withRouter }  from 'react-router-dom'
import  { Redirect}  from 'react-router';
// import passwordHash from 'password-hash';
import { Form , Row , Col, Button } from 'react-bootstrap';
// import Cryptr from 'cryptr';
// import 'react-validation';
import Api from '../../services/api/Api'


class RegisterCrop extends Component {
    constructor(props){
        super(props);
        this.state = { 
        
            login : this.props.params,
            data:{
                Address: "",
                Area: "",
                City: "",
                District: "",
                Mobile: "",
                Organisation_name: "",
                Pincode: "",
                State: "",
                c_name: "",
                c_pwsd: "",
                email: "",
                pwsd: "",
            },
       
            formvalue:{},
            err:{},
            respon:'',
            id:{},
            validated: false,
            move:false,
            stated:[],
            district:[]
        }
        this.api = new Api();
        
    }
    componentWillUnmount = () =>{
 
        localStorage.removeItem('title');

    }    
        handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            this.setState({ validated: true })
        let boole = 0;
        
        let valid = this.state.data;
            
        if( valid['Organisation_name'] === '' ){
            
            boole = 1;
        }
        if(valid['c_name'] === ''){
            
            boole = 1;
        }
        if(valid["Mobile"] === ''){
            
            boole = 1;
        }

        if(valid["Address"] === ''){
            
             boole = 1;
        }
        if(valid["Area"] === ''){
            
             boole = 1;
        }
        if(valid["City"] === ''){
            
             boole = 1;
        }
        if(valid["State"] === ''){
            
             boole = 1;
        }
        if(valid["District"] === ''){
            
             boole = 1;
        }
        if(valid["Pincode"] === ''){
            
             boole = 1;
        }
          
        if(valid["email"] === ''){
        
             boole = 1;

        } if( valid["c_pswd"] === '' ){
        
            boole = 1;
       
       }
        if(valid["pswd"] === '' ){
        
             boole = 1;
        
        }
    
        // console.log(boole);

        if(boole>0){

            // console.log('error');
        }
        else{

            let res = this.api.apiCall("cropsubmit",this.state.data);
            res
            .then(res => {
                        if(res.respond === 'sucess'){   

                            this.setState({move:true,id:res.id});
                            localStorage.setItem('id',res.id);
                            // console.log(res)
                            
                        }else{
                            this.setState({err:'Invalid Credencial'})
                            console.log('fail');
                        }  
                        // console.log(res );
                    })
            .catch(error => { console.error(error); return Promise.reject(error); });

        }
           
        }
        //WARNING! To be deprecated in React v17. Use componentDidMount instead.
        componentWillMount = () => {
            
            let res1 = this.api.apiCallGet("state");
            res1
            .then(res => {
            
                this.setState({ stated:res.data });
           
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
         
        }

    updateState = (e) =>{

        
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        // console.log(data);
        // console.log(this.state.data );

        
    
        if(name === 'State'){

            let res2 = this.api.apiCallGet("district");
            res2
            .then(res => {
                const district = res.data;
                let dst = district.filter(function(dst) {
                  return dst[2] === data[name];
                  })
                this.setState({ district:dst });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
           
        }

    }
    

    render() { 
        // console.log(this.state);
        let {title} = this.state.login;
        localStorage.setItem('id',this.state.id);
        const { validated , move , stated , district } = this.state;
        if(move){

            return(
                <Redirect to={{
                    pathname:'/corp/dashboard',
                }}/>
            );
        }else{
        return (     
            <React.Fragment>
                <div style={{marginTop:40,marginBottom:0,width:1100}} className="container alert alert-primary text-center" role="alert">{title} Registraton</div>
                <div className="mx-auto" style={{width:1100,marginBottom:100,padding:30}}>
                <Form
                 noValidate
                 validated={validated}
                //  onSubmit={e => this.handleSubmit(e)}
                >
                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Organisation Name
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    name="Organisation_name" 
                    onChange={this.updateState}
                     required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Contact Person Name
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    onChange={this.updateState} 
                    name="c_name" 
                    placeholder="" required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                    Contact Person Mobile No
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="number"
                    onChange={this.updateState} 
                    name="Mobile" 
                    placeholder=""
                    maxLength="10"
                    required />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                    Organisation Email
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="email" 
                    name="email" 
                    onChange={this.updateState}
                    placeholder="" required />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Organisation Address
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="textarea"
                    name="Address"                     
                    onChange={this.updateState}
                    placeholder="" required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Area
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="textarea"
                    onChange={this.updateState}
                    name="Area" 

                    placeholder=""required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    City
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    onChange={this.updateState}
                    name="City" 

                    placeholder="" required
                     />
                    </Col>
                </Form.Group>

                            <Form.Group as={Row} >
                            <Form.Label column  sm={4}>State</Form.Label>
                            <Col  sm={8}>
                    
                            <Form.Control onChange={this.updateState} 
                    name="State" 
                    as="select">
                                <option>..choose..</option>
                                 { stated.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                            </Form.Control>
                            </Col>
                            </Form.Group>
                        
                            <Form.Group as={Row} >
                            <Form.Label column sm={4}>District</Form.Label>
                            <Col  sm={8}>
                    
                            <Form.Control onChange={this.updateState} 
                    name="District" 
                    as="select" required>
                                <option>..choose..</option>
                                {  district.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                            )}
                            </Form.Control>
                            </Col>
                            </Form.Group>
                          
                          
                            <Form.Group as={Row} >
                            <Form.Label column sm={4}>Pincode</Form.Label>
                            <Col  sm={8}>
                            <Form.Control onChange={this.updateState} 
                    name="Pincode" 
                    type="number" required/>
                            </Col>
                            </Form.Group>
                            <Form.Group as={Row} >
                            <Form.Label column sm="4">
                            Password
                            </Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"
                            onChange={this.updateState} 
                            name="pwsd" 
                            autoComplete="false"
                            placeholder="" required/>
                            </Col>
                        </Form.Group><Form.Group as={Row} >
                            <Form.Label column sm="4">
                            confirm password 
                            </Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"
                            onChange={this.updateState} 
                            name="c_pwsd" 
                            autoComplete="false"
                            placeholder="" required/>
                            </Col>
                        </Form.Group>
                     

                        {/* <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}                      
                    <Form.Group>
                    <Col className="mx-auto" sm={2}>
                        <Button variant="primary"
                        onClick={this.handleSubmit}
                        type="button">
                            Submit
                        </Button>
                        </Col>
                    </Form.Group>
                      
                        </Form>
                  
                </div>
            </React.Fragment>
         );
        }
    }
}
 
export default withRouter(RegisterCrop);