import React, { Component } from 'react';
import EditPrograms from './programsedit';
// import BatchSplit from './batch';
// import ListOfQuestions from './tezt';


class ProgramsBatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:this.props.id,
         }
    }
    render() { 
        // console.log(this.state.id);
        const { id } = this.state
        return ( 
            
            <React.Fragment>
               
                <div className="container-fluid" style={{marginTop:70,padding:4}}>
                    <div className="jumbotron-fluid">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="nav-Post-tab" data-toggle="tab" href="#nav-Post" role="tab" aria-controls="nav-Post" aria-selected="true">Programs</a>
                        {/* <a className="nav-item nav-link" id="nav-Appl-tab" data-toggle="tab" href="#nav-Appl" role="tab" aria-controls="nav-Appl" aria-selected="false">Batch</a> */}
                        {/* <a className="nav-item nav-link" id="nav-Shortlisted-tab" data-toggle="tab" href="#nav-Shortlisted" role="tab" aria-controls="nav-Shortlisted" aria-selected="false">Shortlisted</a> */}
                        </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-Post" role="tabpanel" aria-labelledby="nav-Post-tab">
                            <EditPrograms id={id}/>
                            {/* <ListOfQuestions /> */}
                            </div>
                            {/* <div className="tab-pane fade" id="nav-Appl" role="tabpanel" aria-labelledby="nav-Appl-tab">
                            <BatchSplit/>
                            </div> */}
                            {/* <div className="tab-pane fade" id="nav-Shortlisted" role="tabpanel" aria-labelledby="nav-Shortlisted-tab">
                            <ShortListedStudent/>
                            </div>    */}
                        </div>
                    
                    </div>
                </div>
            
            </React.Fragment>
         );
    }
}
 
export default ProgramsBatch;