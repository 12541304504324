import React, { Component } from 'react';
// import axios from 'axios';
import Api from '../../../services/api/Api'
// import { Button , ButtonToolbar} from 'react-bootstrap'
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory();

class PostedApplication extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            // url:this.props.url,
            id:"",
            alt:false,
            post:[],
         }
        this.api = new Api();

    }
    componentWillMount = () => {

        let corp_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(corp_id)};
        this.setState({id:ids});

        let res = this.api.apiCall("internshipPostedData",ids);
        res
        .then(res => {
            if(res.respond === 'success'){
                this.setState({ post:res.data,alt:true});
            }else{
                this.setState({alt:false})
            }
            // console.log(res.data)
        })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    tableData = () =>{
        const {alt, post } = this.state;
        if(alt){
            return(
                <tbody>
                {post.map((data,index) =>
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data[2]}</td>
                    <td>{data[14]}</td>   
                    <td>{data[12]}</td>
                    <td>{data[18]}</td>
                    <td>{data[10]}</td>
                    <td>{data[19]}</td> 
                    {/* <td>
                    <ButtonToolbar>
                        <Button variant="info">View</Button>
                        <Button variant="primary">Edit</Button>
                        <Button variant="danger">Delete</Button>
                    </ButtonToolbar>
                    </td>    */}
                   
                </tr>)}
            </tbody>
            );
        }else{
                return(
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="5">No Internship Posted</td>
                    </tr>
                </tbody>
                );
        }
    }
    render() {
        // console.log(this.state.id);
        // const { alt } = this.state
        return ( 
            <React.Fragment>
                 <div className="container-fluid" style={{marginTop:20,padding:4}}>
                    <div className="jumbotron-fluid">
                        <table className="table table-sm">
                            <thead className="thead-light">
                                <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Internship Title</th>
                                <th scope="col">Contact Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Post Invalid Date</th>
                                <th scope="col">No of Candiate</th>
                                <th scope="col">Applied Date</th>
                                {/* <th scope="col">Action</th> */}
                                {/* <th scope="col">Edit</th>                                
                                <th scope="col">Delete</th>*/}
                                </tr>
                            </thead>
                            {this.tableData()}
                        </table>
                        <div>
                            
                        </div>
                    </div>
                </div>

            </React.Fragment>
         );
    }
}
 
export default PostedApplication;