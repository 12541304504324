import React, { Component } from 'react';
// import axios from 'axios';
import { withRouter }  from 'react-router-dom'
// import  { Route , Redirect}  from 'react-router';
// import passwordHash from 'password-hash';
// import Cryptr from 'cryptr';
// import 'react-validation';

class RegisterHr extends Component {
    constructor(props){
        super(props);
        this.state = { 
            login : this.props.params,
            data:{},
            // url:'http://192.168.1.21/bskill/src/db.php',
            formvalue:{},
            err:{},
            respon:''          
        }
        
    }

    //     this.dataValidate();

    // }

     dataValidate = (e) => {

        // e.preventDefault();
        // let valid = this.state.data;
        // // let error = this.state.err;
        // let boole = 0;
        
    
        // if( valid['first_name'] === '' ){
        //     // this.setState({error['first_name'] : "enter the name"});
        //     boole = 1;
        // }
        // if(valid['last_name'] === ''){
            
        //     boole = 1;
        // }
        // if(valid["dob"] === ''){
            
        //     boole = 1;
        // }

        // if(valid["fathername"] === ''){
            
        //      boole = 1;
        // }
        // if(valid["gender"] === ''){
            
        //      boole = 1;
        // }
        // if(valid["mothername"] === ''){
            
        //      boole = 1;
        // }
        // if(valid["password"] === ''){
            
        //      boole = 1;
        // }
        // if(valid["relatype"] === ''){
            
        //      boole = 1;
        // }
        // if(valid["phone"] === ''){
            
        //      boole = 1;
        // }
          
        // if(valid["email"] === ''){
        
        //      boole = 1;

        // }else{
            
        //     boole = valid["email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? boole = 0 : boole = 1 ;

        // }

        // if(valid["pasch"] === '' || valid["password"] === '' ){
        
        //      boole = 1;
        
        // }else if( valid["pasch" ] === valid["password"]){
        
        
        //     boole = 1;
    
        // }else{

        //     boole = 0;

        // }
        // if(boole>0){

        //     return true;
        // }
        // else{

        //     return false;
        // }
        
     }
    //  componentDidMount() {
    
     handleSubmit = () => {
        
    //   e.preventDefault()
      console.log(this.state);
       
        // axios({
        // method: 'post',
        // url: this.state.url+"?type=cropsubmit",
        // data: this.state.data,
        // config: { headers: {'Content-Type': 'application/json'}}
        //  })
        // .then(res => {
        //     if(res.data.respond === 'sucess'){
        //         this.props.history.push('/student');                
        //         console.log(res.data );
        //     }else{
        //         this.props.history.push('/register');
        //         console.log('fail');
        //     }
        // })
        // .catch(error => console.error('Error:', error));
        this.setState({err:"Under Progress"});

        // return(
        //     <span className="text-danger">Under Progress</span>
        // );



    }

    updateState = (e) =>{

        this.dataValidate();
        
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        if( name === 'password'){

            // const cryptr = new Cryptr('PassW@#');
            // // let pass = passwordHash.generate(value);
            // const encryptedString = cryptr.encrypt(value);
            // // const decryptedString = cryptr.decrypt(encryptedString);
            // data[name] = encryptedString;
            // this.setState({data});
            // console.log(encryptedString);
            // console.log(decryptedString);
        }
        // let tes = passwordHash.isHashed(data[name])
        console.log(data);
        console.log(this.state.data );

    }
    

    render() { 
        // console.log(this.state.data[0].first_name);
        // let data = this.state.data;        
        let {title} = this.state.login;

        return (     
            <React.Fragment>
                <div style={{marginTop:40,marginBottom:0,width:1100}} className="container alert alert-primary text-center" role="alert">{title} Registraton</div>
                <div className="mx-auto" style={{width:1100,marginBottom:100,padding:30}}>
                        {/* <form onSubmit={e => this.handleSubmit(e)}> */}
                        <form>
                        <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">First Name</label>
                                    <div className="col-sm-10">
                                    <input type="text" name="first_name" onChange={e => this.updateState(e)} className="form-control"/>
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Last Name</label>
                                    <div className="col-sm-10">
                                    <input type="text" name="last_name" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                            <label  className="col-sm-2 col-form-label">Relationship Type</label>
                            <div className="col-sm-10">
                            <div className="form-check form-check-inline">
                                    <input className="form-check-input" onChange={e => this.updateState(e)} style={{height:17,width:17}} type="radio" name="relatype" id="exampleRadios1" value="Son"/>
                                    <label className="form-check-label" >
                                        Son
                                    </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" onChange={e => this.updateState(e)} style={{height:17,width:17}} type="radio" name="relatype" id="exampleRadios2" value="Daughter"/>
                                    <label className="form-check-label" >
                                    Daughter
                                    </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" onChange={e => this.updateState(e)} style={{height:17,width:17}} type="radio" name="relatype" id="exampleRadios3" value="Wife"/>
                                    <label className="form-check-label" >
                                    Wife
                                    </label>
                            </div>
                            </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Father/Husband Name</label>
                                    <div className="col-sm-10">
                                    <input type="text" name="fathername" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Mother Name</label>
                                    <div className="col-sm-10">
                                    <input type="text" name="mothername" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Email</label>
                                    <div className="col-sm-10">
                                    <input type="email" name="email" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Phone Number</label>
                                    <div className="col-sm-10">
                                    <input type="number" name="phone" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                                <label  className="col-sm-2 col-form-label">Date Of Birth</label>
                                    <div className="col-sm-10">
                                    <input type="date" name="dob" className="form-control"  onChange={e => this.updateState(e)} />
                                    </div>
                            </div>
                            <div className="form-group row">
                            <label  className="col-sm-2 col-form-label">Gender</label>
                            <div className="col-sm-10">
                            <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" style={{height:17,width:17}} onChange={e => this.updateState(e)} name="gender" id="Radios1" value="Female"/>
                                    <label className="form-check-label" >
                                    Female
                                    </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" style={{height:17,width:17}} onChange={e => this.updateState(e)} name="gender" id="Radios2" value="Male"/>
                                    <label className="form-check-label" >
                                    Male
                                    </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" style={{height:17,width:17}} onChange={e => this.updateState(e)} name="gender" id="Radios3" value="Transgender"/>
                                    <label className="form-check-label" >
                                    Transgender
                                    </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Password</label>
                                <div className="col-sm-10">
                                <input type="password" name="password" className="form-control" placeholder="Password"  onChange={e => this.updateState(e)}  autoComplete="false"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Confirm Password</label>
                                <div className="col-sm-10">
                                <input type="password" name="pasch" className="form-control" placeholder="Confirm Password"  onChange={e => this.updateState(e)}  autoComplete="false"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-5 col-form-label"></label>
                                <div className="col-sm-6">
                                <button type="button" disabled={this.dataValidate()} id="submittest" onClick={this.handleSubmit} className="btn btn-primary">Register</button>
                                </div>
                                <span className="text-danger">{ this.state.err!== null ? this.state.err : "" }</span>
                            </div>
                        </form>
                  
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(RegisterHr);