import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEye ,faAddressCard , faList} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

class StuHome extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            c_name:this.props.name,
            id:this.props.id
         }
    }
    componentDidMount = () =>{
        // console.log("hi");
    }
    render() { 
        // console.log(this.props);
        let { name } = this.props;
        return ( 
            <React.Fragment>
                     <div style={{marginTop:70}}>
                     <h1 className="p-3 mt-5">  Welcome <span className="text-primary font-italic"> {name} </span> </h1>
                     </div>
                     <div className="container-fluid">
                        <div className="jumbotron">
                        
                            <div className="row">
                                {/* <div className="col-3">
                                <div className="card ">
                                <div className="card-body mx-auto">
                                        <FontAwesomeIcon style={{width:130,height:130}} icon={faChalkboardTeacher}/>
                                    </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/program',
                             
                                    }}>
                                    Programs
                                </Link>
                                </div>
                                </div> */}
                                <div className="col-3">
                                <div className="card ">
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faEye}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/edit',
                          
                                    }}>
                                Edit Application
                                    </Link>
                                </div>
                                </div>
                                <div className="col-3">
                                <div className="card ">                            
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faAddressCard}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/profile',
       
                                    }}>
                                Profile
                                    </Link>
                                </div>
                                </div>
                                <div className="col-3">
                                <div className="card ">                            
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faList}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/course',
 
                                    }}>
                                 Courses
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                     </div>
            </React.Fragment>
         );
    }
}


export default StuHome;