import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import logo from '../images/broadline_logo.png'

class NavBar extends Component {
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         // data:this.props,
    //     }
    // }
    render() { 
        // console.log(this.state);
        return ( 
            <React.Fragment>
                <nav className="navbar navbar-dark " style={{backgroundColor:'#17aafa'}}>
                    <span className="navbar-brand md h1" style={{backgroundColor:'#17aafa',fontFamily:"jokerman"}}>BROADSKILLS</span>
                        <ul className="nav justify-content-end">
                            <li className="nav-item" style={{paddingRight:10}}>
                                <Link className="btn btn-outline-light my-2 my-sm-0" to='/'>Home</Link>  
                            </li>
                            {/* <li className="nav-item">
                                <Link className="btn btn-outline-light my-2 my-sm-0" to='/login' >Login</Link>
                            </li> */}
                        </ul>
                </nav>
            </React.Fragment>
         );
    }
}
 
export default NavBar;