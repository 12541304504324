import React, { Component } from 'react';
import { Form , Row , Col , Pagination} from 'react-bootstrap';
// import axios from 'axios';
import Api from '../../../services/api/Api'

// import Pagination from "react-js-pagination";

class ShortListing extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            data:{gender:'Male'},
            filted:[],
            hits:[],
            stated:[],
            url:this.props.url,
            alt:true,
            PageLimit:10, 
            initiallimit:0,
            page:1,
            range:"",
            pagerangNext:true,
            pagerangPrev:true,
            // activePage:1    
         }
        this.api = new Api();

    }
    onChangeData = (e) =>{

         
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});

        let res = this.api.apiCall("Shortlist",this.state.data);
        res
        .then(result => {
            if(result.respon === 'sucess'){

                    this.setState({ filted:result.filter });
                    this.setState({ range:result.filter.length });
                    // console.log(result.data);
                    this.setState({alt:true})
                    this.setState({pagerangNext:false,pagerangPrev:false})
                    this.setState({initiallimit:0,PageLimit:10,page:1}) 
        
                    }else{
                    this.setState({alt:false})
                    }
                // console.log(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    

    movePrev = () =>{

        const { initiallimit } = this.state

        if(initiallimit !== 0){
            const initiallimit = this.state.initiallimit - 10;
            const PageLimit = this.state.PageLimit - 10;
            const page = this.state.page - 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }
    moveNext = () =>{

        const { filted } = this.state

        if(filted !== '' ){
        const initiallimit = this.state.initiallimit + 10;
        const PageLimit = this.state.PageLimit + 10;
        const page = this.state.page + 1;
            this.setState({
                initiallimit:initiallimit,
                PageLimit:PageLimit,
                page:page
            });
        }

    }


    componentDidMount = () => {

        let res = this.api.apiCallGet("state");
        res
        .then(result => {
                        this.setState({ stated:result.data });
                        // console.error(result);
            })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
    tableData = (filted) =>{
        const alt = this.state.alt;

        const { 
            initiallimit , 
             PageLimit,
             page,
             pagerangNext,
             pagerangPrev,
             range
            } = this.state

        let pagerangNexts = pagerangNext;
        const subset = filted.slice(initiallimit, PageLimit);

        const ranges = ( range === '' ) ? '0' : range;
        if(subset.length<10){
            // console.log(subset);
            pagerangNexts = true;
        }


        if(alt){
            return(
                <tbody>
                {subset.map((data,index) =>
                <tr key={index}>
                    <td>{index + (initiallimit + 1)}</td>
                    <td>{data[1]}</td>
                    <td>{data[2]}</td>   
                    <td>{data[4]}</td>
                    <td>{data[3]}</td>
                    <td>{data[5]}</td>
                    {/* <td></td> */}
                    <td>{data[6]}</td>
                    {/* <td>
                        <div className="mx-auto">
                            <input className="form-check-input" name={data[0]} value={data[0]} onChange={this.markPerson} type="checkbox"/>
                        </div>
                    </td> */}
                </tr>)}
                <tr className="p-4 m-2" style={{paddingTop:40}}>
                        <td colSpan={3}></td>
                        <td colSpan={3}>
                            <Pagination style={{paddingTop:20}}>
                                <Pagination.Prev onClick={this.movePrev} disabled={pagerangPrev}/>
                                <Pagination.Item active>{page}</Pagination.Item>
                                <Pagination.Next onClick={this.moveNext} disabled={pagerangNexts}/>
                                <p className="text-primary align-middle h6 p-2">
                                &nbsp; Total File Count &nbsp;
                                </p>
                                <Pagination.Item >{ranges}</Pagination.Item>          
                            </Pagination>
                        </td>
                        <td></td>
                    </tr>
            </tbody>
            );
        }else{
                return(
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td colSpan="5">No Data Founded</td>
                    </tr>
                </tbody>
                );
        }
    }
    // handlePageChange = (pageNumber) => {
    //     console.log(`active page is ${pageNumber}`);
    //     this.setState({activePage: pageNumber});
    //   }
    // markPerson = (e) =>{

    //     let data = this.state.hits;
    //     let name = e.target.name;
    //     let value = e.target.value;
            
    //     data[name] = value;

    //     this.setState({hits:data});
    //     console.log(this.state.hits);
    // } 
    render() { 
        // console.log(this.state);
        let { 
            filted ,
            //  stated
             } = this.state;
        // filted = ( filted === null ) ? '' : filted;
        return ( 
            <React.Fragment>
                <div className="container-fluid" style={{marginTop:70,padding:4}}>
                    <div className="jumbotron-fluid">
                    <div className="pos-f-t">
                            <div className="collapse show" id="navbarToggleExternalContent">
                                <div className="bg-light p-4">
                                    <div className="jumbotron-fluid">
                                    <Form className="p-4">
                                        <Form.Group as={Row} controlId="formGridState">
                                                <Form.Label>State</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="state" onChange={this.onChangeData}>
                                                        <option value="">..Choose..</option>
                                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                        <option value="BIHAR">BIHAR</option>
                                                        <option value="ODISHA">ODISHA</option>
                                                        <option value="Rajasthan">Rajasthan</option>
                                                        <option value="WESTBENGAL">WESTBENGAL</option>
                                                         {/* { stated.map((stated,ind) => <option key={ind} value={stated[1]}>{stated[1]}</option>
                                                            )} */}

                                                </Form.Control>
                                                </Col>
                                                <Form.Label>Sector</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="sector" onChange={this.onChangeData}>
                                                    <option value="">Choose...</option>
                                                    <option value="Health Care">Health Care </option>
                                                    <option value="Apparel, Made-ups & Home Furnishing">Apparel, Made-ups & Home Furnishing</option>
                                                    <option value="Banking & Accounting">Banking & Accounting</option>
                                                    <option value="Electronics">Electronics</option>
                                                    <option value="Electrical">Electrical</option>
                                                    <option value="Garment Making">Garment Making</option>
                                                    <option value="Retail">Retail</option>
                                                    <option value="Telecom">Telecom </option>
                                                    <option value="Capital Goods">Capital Goods</option>
                                                    <option value="Tourism and Hospitality">Tourism and Hospitality</option>
                                                </Form.Control>
                                                </Col>
                                            <Form.Label>Gender</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="gender" onChange={this.onChangeData}>
                                                    <option value="">..Choose...</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Transgender">Transgender</option>
                                                </Form.Control>
                                                </Col>
                                                {/* <Form.Label>District</Form.Label>
                                                <Col>
                                                <Form.Control as="select" name="district" onChange={this.onChangeData}>
                                                    <option>Choose...</option>
                                                    <option>...</option>
                                                </Form.Control>
                                                </Col> */}
                                        </Form.Group>
                                    </Form>
                                    </div>
                                </div>
                            </div>
                            <nav className="navbar navbar-dark bg-dark ">
                            <span className="p-2 text-light font-weight-bolder"> Student List</span>
                                <div className="float-right">
                                    <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                    Filter <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </nav>
                    </div>
                        <table className="table table-sm">
                            <thead className="thead-dark">
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Candidate Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">State</th>
                                <th scope="col">District</th>
                                <th scope="col">Sector</th>
                                {/* <th scope="col">Job Role</th> */}
                                <th scope="col">Aadhar no</th>
                                {/* <th scope="col">Mark</th> */}
                                </tr>
                            </thead>
                            {this.tableData(filted)}
                        </table>
                        <div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default ShortListing;