import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase , faEye ,faAddressCard , faList} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

class CorpHome extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            c_name:this.props.name,
            id:this.props.id
         }
    }
    componentDidMount = () =>{
        // console.log("hi");
    }
    render() { 
        // console.log(this.props);
        let { name } = this.props;
        return ( 
            <React.Fragment>
                     <div style={{marginTop:70}}>
                             <h5 className="h3 thead-dark p-1">
                                Welcome      
                                <span className="text-primary p-3" style={{left:5}}>
                                        { name }
                                </span>
                             </h5>
                     </div>
                     <div className="container-fluid">
                        <div className="jumbotron">
                        
                            <div className="row">
                                <div className="col-3">
                                <div className="card ">
                                <div className="card-body mx-auto">
                                        <FontAwesomeIcon style={{width:130,height:130}} icon={faBriefcase}/>
                                    </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/post',
                                    // state:id
                                    }}>
                                    Post Internship
                                </Link>
                                </div>
                                </div>
                                <div className="col-3">
                                <div className="card ">
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faEye}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/manage',
                                    // state:id
                                    }}>
                                Manager
                                    </Link>
                                </div>
                                </div>
                                <div className="col-3">
                                <div className="card ">                            
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faAddressCard}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/profile',
                                    // state:id
                                    }}>
                                Profile
                                    </Link>
                                </div>
                                </div>
                                <div className="col-3">
                                <div className="card ">                            
                                <div className="card-body mx-auto">
                                    <FontAwesomeIcon style={{width:130,height:130}} icon={faList}/>
                                </div>
                                </div>
                                <div className="card-header mx-auto">
                                <Link className="btn-link" to={{
                                    pathname:'/shortlist',
                                    // state:id
                                    }}>
                                Shortlist
                                    </Link>
                                </div>
                                </div>
                            </div>
                        </div>
                     </div>
            </React.Fragment>
         );
    }
}
export default CorpHome;
