import React, { Component } from 'react';
// import axios from 'axios';
import Api from '../../../services/api/Api'

class CourseDetail extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            courses:{},
            // id:this.props,
            // url:'http://192.168.1.21/bskill/src/db.php',
            test:false
         }
        this.api = new Api();

    }
    componentWillMount = () =>{

        let stud_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(stud_id)};
        this.setState({id:ids});
        // console.log(ids);

        let res = this.api.apiCall("getcourses",ids);
        res
        .then(res => {
            
            if(res.respon === 'sucess'){
                this.setState({
                    courses:res.userdata,
                });
                this.setState({test:true});

            }else{
                this.setState({test:false});
            }
        })
        .catch(error => { console.error(error); return Promise.reject(error); });
     
                
    }
    render() { 
        // console.log(this.state);
        const { courses , test } = this.state;
        if(test){
            return ( 
                <React.Fragment>
                    <div className="container-fluid" style={{marginTop:60}}>
                    
                        <div className="jumbotron">
                            
                            <p className="font-weight-bold">Courses Details</p>
                            <table className="table table-sm table-dark">
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Course Name</th>
                                    <th scope="col">Centre Name</th>
                                    <th scope="col">Joined Date</th>
                                    <th scope="col">Ended Date</th>
                                    <th scope="col">batch Id</th>
                                    <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>{courses.couses_name}</td>
                                    <td>{courses.centre_name}</td>
                                    <td>{courses.course_joined}</td>
                                    <td>{courses.course_ended}</td>
                                    <td>{courses.temp_batch_id}</td>
                                    <td>Completed</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
             );
            
        }
        else{
            return(
                <React.Fragment>
                     <div className="container-fluid" style={{marginTop:60}}>
                    
                    <div className="jumbotron">
                        
                        <p className="font-weight-bold">Courses Details</p>
                        <p>You did't apply any courses</p>
                        </div></div>
                </React.Fragment>
            );
        }
    }
}
 
export default CourseDetail;