import React, { Component } from 'react';
import Navdashboard from './nav';
// import { BrowserRouter , NavLink , Route } from 'react-router-dom'
// import RegisterCrop from './registerform_crop'
class Corpdash extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            id:"",
        }
    }
    
    componentWillMount = () =>{

        let corp_id = localStorage.getItem('id');
        let ids = {"id":JSON.parse(corp_id)};
        this.setState({id:ids});
        
    }
    render() { 
        const { id } = this.state
        return ( 
            <React.Fragment>
                <Navdashboard id={id}/>
            </React.Fragment>
         );
    }
}
 
export default Corpdash;