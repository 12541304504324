import React, { Component } from 'react';
// import NavBarapp from './navbar_app';
import NavBar from './navbar';
import Footer from './footer';
// import { withRouter }  from 'react-router-dom'
import Regcontent from '../components/student/students';

class Studentregistration extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            id:{},
            ids:this.props
         }
    }
    // componentWillMount = () =>{
    //     this.setState({id:this.props});
    // }
    render() { 
        // console.log(this.props);
        // console.log(this.state);
        return ( 
            <React.Fragment>
                <NavBar/>
                <Regcontent data={this.state.ids}/>
                <Footer/>
            </React.Fragment>
         );
    }
}
 
export default Studentregistration;