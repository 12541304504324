import React, { Component } from 'react';
import { Form , Col , Row , Button } from 'react-bootstrap'
// import Cryptr from 'cryptr';
// import './formlog_js.js';
import { 
    // withRouter ,
    Redirect 
    } from 'react-router-dom';
    
import Api from '../services/api/Api'
    
    class LoginPage extends Component {
        constructor(props){
            super(props)
        
        this.state = { 
            userdata:{
                mobile: "",
                pwsd: "",
                utype: "1"
            },
            err:{},
            classes:{},
            validlog:false,
            id:{},
            path:{},
            boole:{},
            alrt:false,
            validated:false,
            respond:{}
            
        }

        this.api = new Api();

    }
    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        this.setState({ validated: true })
    let boole = 0;
    
    let valid = this.state.userdata;
      
    if(valid["utype"] === ''){
    
         boole = 1;

    } 
    if( valid["mobile"] === '' ){
    
        boole = 1;
   
   }
    if(valid["pwsd"] === '' ){
    
         boole = 1;
    
    }

    // console.log(boole);

    if(boole>0){

        // console.log('error');
    }
    else{
        
        let res = this.api.apiCall("login",this.state.userdata);
        res
        .then(result => {
            if(result.respon === 'sucess'){
               
                this.setState({validlog:true,path:result})
                // localStorage.setItem('id', result.id);
                // console.log(result)

            }else{

                this.setState({err:'Invalid User Mobile Number & Password',alrt:true})
                // console.log(this.state.err);
            }
        })
        .catch(error => { console.error(error); return Promise.reject(error); });

    }
        

    }
 
     onUpdate = (e) =>{

         e.preventDefault();
         let data = this.state.userdata;
         let name = e.target.name;
         let value = e.target.value;
         
         data[name] = value;
         
         
         
         
         this.setState({data});
        //  this.validData();
         // if( name === 'pwsd'){
            
        //     const cryptr = new Cryptr('PassW@#');
        //     // let pass = passwordHash.generate(value);
        //     const encryptedString = cryptr.encrypt(value);
        //     // const decryptedString = cryptr.decrypt('58aecb9b28511a8d6a99d2f44bb7e7d5d307fc');
        //     data[name] = encryptedString;
        //     this.setState({data});
        //     // console.log(encryptedString);
        //     // console.log(decryptedString);
        // }
        // console.log(data);
     }
     errorFunc = () =>{
        if(this.state.boole===2){
            return this.state.err;
        }
     }
    render() { 
        // console.log(this.state);
        localStorage.setItem('id', this.state.path.id);
        const log = this.state.validlog;
        if(log){
            return(
                <Redirect to={{
                            pathname:this.state.path.locat,
                        }}/>
            );
        }
        else{
        // console.log(this.state);
        const { 
            alrt , err ,
             validated } = this.state
        return ( 
            <React.Fragment>
                <div className="container" style={{paddingTop:40,marginTop:60,marginBottom:50}}>
                    <div className="row">
                        <div className="offset-md-3 col-md-12 align-self-center">
                            <div className="card w-50">
                                <h5 className="card-header">Login</h5>
                                    <div className="card-body">
                                        <div className="container">
                                            {/* <form  className="needs-validation" noValidate>
                                                <div className="form-group">
                                                        <label >User Type</label>
                                                        <select className="custom-select" name="utype" onChange={this.onUpdate}>
                                                            <option defaultValue>...Choose...</option>
                                                            <option value="1">Industrial Partner</option>
                                                            <option value="2">Student</option>
                                                            <option value="3">Job MarketPlace</option>
                                                            <option value="4">Centres</option>
                                                            <option value="5">Goverment</option>
                                                        </select>
                                                    <span className="text-danger mx-auto"> { boole === 1 ? valierr.typ : ''} </span>
                                                </div>
                                                <div className="form-group">
                                                        <label >Mobile Number</label>
                                                        <input type="number" className="form-control" onChange={this.onUpdate} name="mobile" aria-describedby="emailHelp" placeholder="Enter Mobile Number" required/>
                                                <span className="text-danger mx-auto"> { boole === 2 ? valierr.mobil : ''} </span>
                                                </div>
                                                <div className="form-group">
                                                        <label >Password</label>
                                                        <input type="password" className="form-control" onChange={this.onUpdate} name="pwsd" placeholder="Password" autoComplete="false" required/>
                                                    <span className="text-danger mx-auto"> { boole === 3 ? valierr.pasd : ''} </span>
                                                </div>
                                                <div className="form-group">
                                                    <span className="text-danger mx-auto">{ alrt === true ? err : ''}</span>
                                                </div>
                                                <button style={{marginLeft:200}} onClick={this.onHandle} type="button" className="btn btn-primary">Submit</button>
                                            </form> */}
                                             <Form
                                                noValidate
                                                validated={validated}
                                                
                                                >
                                                
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm={4} >User Type</Form.Label>
                                                    <Col sm={8}>
                                                    <Form.Control as="select" name="utype" onChange={this.onUpdate}>
                                                            {/* <option defaultValue>...Choose...</option> */}
                                                            <option value="1">Industrial Partner</option>
                                                            <option value="2">Student</option>
                                                            <option value="3">Job MarketPlace</option>
                                                            <option value="4">Centres</option>
                                                            <option value="5">Goverment</option>
                                                    </Form.Control>
                                                    </Col>
                                                </Form.Group>
                                               
                                                
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm={4} >Mobile Number</Form.Label>
                                                    <Col sm={8}>
                                                    <Form.Control
                                                    required
                                                    type="number"
                                                    placeholder="Mobile Number"
                                                    onChange={this.onUpdate} name="mobile"
                                                    />
                                                    </Col>
                                                </Form.Group>                                                
                                                <Form.Group as={Row}>
                                                    <Form.Label column sm={4} >Password</Form.Label>
                                                    <Col sm={8}>
                                                    <Form.Control
                                                    required
                                                    type="Password"
                                                    placeholder="Password"
                                                    onChange={this.onUpdate} name="pwsd"
                                                    />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                <span className="text-danger mx-auto">{ alrt === true ? err : ''}</span>
                                                </Form.Group>
                                                <Form.Group as={Row}>
                                                <Col className="mx-auto" sm={2}>
                                                    <Button type="button" onClick={e => this.handleSubmit(e)} >Login</Button>
                                                </Col>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="container" style={{height:100,width:200}}></div>
                
            </React.Fragment>

         );
        }
    }
}
 
// LoginPage.propTypes = {
//     number: PropTypes.string.required(),
//     utype: PropTypes.string.required(),
//     password: PropTypes.string.required(),
//   };

export default LoginPage;
