import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import Indx from './indx';
import { 
  // BrowserRouter as Router ,
   Route ,
    Switch ,
    HashRouter,
    // hashHistory
 } from 'react-router-dom';
import Login from './Login';
import Register from './register';
import Studentregistration from './components/application';
import Studentdash from './components/dashboard/stu_dash/dash'
import Corpdash from './components/dashboard/corp_dash/dash';
import Centredash from './components/dashboard/centre_dash/dash';
// import { 
//   // createHashHistory,
// createBrowserHistory
//  } from "history";

// const history = createBrowserHistory();

// const history = createHashHistory()

// console.log(history);
class App extends Component {
  
  state = {

    url:'http://192.168.1.21/bskill/src/db.php',
    
  }
  
  render() {
    // console.log(process.env.PUBLIC_URL);
    // console.log(this.state);
    return (
      <HashRouter>
      {/* <Indx /> */}
          <Switch>
          <Route path="/" exact={true} strict render ={ () =>{
            return(<Indx />);
          }
          }
          />
          <Route path="/login" exact={true} strict render ={ () =>{
            return(
              <Login />
            );
          }
          }/> 
          <Route path="/register" exact={true} strict render={ (props) =>{
              return(
                <Register/>
                );
          }
          }
          />
          <Route path="/student" exact={true} strict render={ (props) =>{

              return(
                <Studentregistration />
                );
          }
          }/>
          <Route path="/student/dashboard" exact={false} strict render={ (props) =>{
              return(
                <Studentdash  url={this.state.url}/>
                );
          }
          }/>
         
          <Route path="/corp/dashboard" exact={false} strict 
          render={ (props) =>{

            return(
              <Corpdash />
              );
            }
          }
          />
          <Route path="/centre/dashboard" exact={false} strict render={ (props) =>{

            return(
              <Centredash/>
              );
            }
          }/>
          <Route render={ () =>{

            return(
              <div>
                Page Not Found
              </div>
            );
          }
          }/>
          </Switch>
       </HashRouter>
    );
  }
}

export default App;
