import React, { Component } from 'react';
// import corp from '../images/Corp_build.png';
// import stu from '../images/stu_logo.png';
// import hr from '../images/hr.png';
// import job from '../images/job.png';
// import gov from '../images/gov.png';
import { Link , withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBuilding , faUserGraduate , 
    faUniversity , faUserTie , 
    faBriefcase  
} from '@fortawesome/free-solid-svg-icons'
// import { arrayOf } from 'prop-types';
// import Login from '../Login';

class Logins extends Component {

    state = {
        login:[
            {
                id:1,
                loc:faUserGraduate,
                title:'Student',
            },
            {
                id:2,
                loc:faBriefcase,
                title:'Training Centre',
            },
            {
                id:3,
                loc:faBuilding,
                title:'Industrial Partner',
            },
            {
                id:4,
                loc:faUserTie,
                title:'Job MarketPlace',
            },
            {
                id:5,
                loc:faUniversity,
                title:'Goverment',
            }
        ]
    }
    sendReg = (title) => {

       
       let ids = JSON.stringify(title);
        localStorage.setItem('title',ids);
        // console.log(ids);
        // this.setState({id:ids});
    }
    render() { 
        return ( 
            <React.Fragment>
                <div className="container container-fluid"  style={{paddingTop:10,marginBottom:70}}>
                    <div className="container" style={{textAlign:'center'}}>
                            <h1 className="display-6">Choose your option to Register</h1>
                        <div className="row">
                    {this.state.login.map((log,ind) => 
                            <div key={log.id} className="col">
                            <div className="" style={{broder:0}}>  

                                <div className="card-body" style={{textAlign:'center',broder:'1px'}}>     
                                    <Link onClick={e => this.sendReg(log)} to={{
                                        pathname: "/register",
                                        }}>
                                        {/* <img src={log.loc} style={{paddingBottom:20,border:0,borderRadius:15,height:120,width:120}} alt="..." className="img-thumbnail"/> */}
                                        <FontAwesomeIcon className="p-2" style={{paddingBottom:20,border:0,borderRadius:15,height:120,width:120}} icon={log.loc}/>
                                    </Link>
                                </div>
                                <div className="card-header" style={{broder:0}}><h5 className="md h6">{log.title}</h5></div>
                            </div>
                            </div>
                           )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default withRouter(Logins);

