import React, { Component } from 'react';
import PostedApplication from './postapplied';
import Applied from './applied';
// import ShortListedStudent from './shortlisted';
// import { Route ,
//     //  HashRouter ,
//     BrowserRouter as Router ,
//       NavLink } from 'react-router-dom';
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory()


class ManageCorp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id:this.props.id,
            url:this.props.url
         }
    }
    render() { 
        // console.log(this.state);
        const { 
            id ,
             url 
            } = this.state;
        return ( 
            
            <React.Fragment>
               
                <div className="container-fluid" style={{marginTop:70,padding:4}}>
                    <div className="jumbotron-fluid">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a className="nav-item nav-link active" id="nav-Posted-tab" data-toggle="tab" href="#nav-Posted" role="tab" aria-controls="nav-Posted" aria-selected="true">Posted</a>
                        <a className="nav-item nav-link" id="nav-Applied-tab" data-toggle="tab" href="#nav-Applied" role="tab" aria-controls="nav-Applied" aria-selected="false">Applied</a>
                        {/* <a className="nav-item nav-link" id="nav-Shortlisted-tab" data-toggle="tab" href="#nav-Shortlisted" role="tab" aria-controls="nav-Shortlisted" aria-selected="false">Shortlisted</a> */}
                        </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-Posted" role="tabpanel" aria-labelledby="nav-Posted-tab">
                            <PostedApplication id={id} url={url}/>
                            </div>
                            <div className="tab-pane fade" id="nav-Applied" role="tabpanel" aria-labelledby="nav-Applied-tab">
                            <Applied/>
                            </div>
                            {/* <div className="tab-pane fade" id="nav-Shortlisted" role="tabpanel" aria-labelledby="nav-Shortlisted-tab">
                            <ShortListedStudent/>
                            </div>    */}
                        </div>
                    
                    </div>
                </div>
            
            </React.Fragment>
         );
    }
}
 
export default ManageCorp;