import React, { Component } from 'react';
import { withRouter , Redirect }  from 'react-router-dom';
import { Form , Row , Col , Button } from 'react-bootstrap';
// import passwordHash from 'password-hash';
// import Cryptr from 'cryptr';
// import 'react-validation';
import Api from '../../services/api/Api'


class RegisterCentre extends Component {
    constructor(props){
        super(props);
        this.state = { 
            login : this.props.params,
            data:{
                IdProof: "",
                Mobile: "",
                Centre_name: "",
                Organizationtype: "",
                c_pwsd: "",
                city: "",
                country: "",
                district: "",
                dob: "",
                email: "",
                file_name: "",
                first_name: "",
                gender: "",
                pwsd: "",
                role: "",
                state: "",
            },
            formvalue:{},
            err:{},
            respon:'',
            country:[],
            stated:[],
            district:[],
            validated:false,
            move:false,
            id:""
            
        }
        this.api = new Api();
        
    }

    componentDidMount = () =>{
       
        let res = this.api.apiCallGet("country");
        res
        .then(res => {
            const country = res.data;
            this.setState({ country });
          //   console.log(persons);
          })
        .catch(error => { console.error(error); return Promise.reject(error); });
    }

    
     handleSubmit = (event) => {
        
     const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({ validated: true })

    let boole = 0;
        
    let valid = this.state.data;
        
    if( valid['Center_name'] === '' ){
        
        boole = 1;
    }
    if(valid['Organizationtype'] === ''){
        
        boole = 1;
    }
    if(valid["Mobile"] === ''){
        
        boole = 1;
    }

    if(valid["country"] === ''){
        
         boole = 1;
    }
    if(valid["district"] === ''){
        
         boole = 1;
    }
    if(valid["role"] === ''){
        
         boole = 1;
    }
    if(valid["state"] === ''){
        
         boole = 1;
    }
    if(valid["first_name"] === ''){
        
         boole = 1;
    }
    if(valid["dob"] === ''){
        
         boole = 1;
    }
      
    if(valid["email"] === ''){
    
         boole = 1;

    } if( valid["c_pswd"] === '' ){
    
        boole = 1;
   
   }
    if(valid["pswd"] === '' ){
    
         boole = 1;
    
    }

    // console.log(boole);

    if(boole>0){

        // console.log('error');
    }
    else{

        let res = this.api.apiCall("centresubmit",this.state.data);
        res
        .then(reslt => {
            // console.log(reslt)
                    if(reslt.respond === 'sucess'){   

                        this.setState({move:true,id:reslt.id});
                        // localStorage.setItem('id',reslt.id);
                        
                    }else{
                        this.setState({err:'Data Failed Error Credencial'})
                        // console.log('fail');
                    }  
                    // console.log(reslt);
                    // console.log('success');
                })
        .catch(error => { console.error(error); return Promise.reject(error); });
    }
    }

    updateState = (e) =>{
        
        e.preventDefault();
        let data = this.state.data;
        let name = e.target.name;
        let value = e.target.value;
            
        data[name] = value;

        this.setState({data});
        
        // if( name === 'password'){

        //     const cryptr = new Cryptr('PassW@#');
        //     // let pass = passwordHash.generate(value);
        //     const encryptedString = cryptr.encrypt(value);
        //     // const decryptedString = cryptr.decrypt(encryptedString);
        //     data[name] = encryptedString;
        //     this.setState({data});
        //     // console.log(encryptedString);
        //     // console.log(decryptedString);
        // }
        // // let tes = passwordHash.isHashed(data[name])
        // console.log(data);
        // console.log(this.state.data );

        if(name === 'country'){

            let res1 = this.api.apiCallGet("state");
            res1
            .then(res => {
            
                const stated = res.data;
                   let task = stated.filter(function(task) {
                    return task[2] === data[name];
                    })
                this.setState({ stated:task });
           
              })
            .catch(error => { console.error(error); return Promise.reject(error); });

        }

        if(name === 'state'){

            let res2 = this.api.apiCallGet("district");
            res2
            .then(res => {
                const district = res.data;
                let dst = district.filter(function(dst) {
                  return dst[2] === data[name];
                  })
                this.setState({ district:dst });
              })
            .catch(error => { console.error(error); return Promise.reject(error); });
           
        }

    }
    

    render() { 
        // console.log(this.state.data[0].first_name);
        let { title } = this.state.login;
        let { 
            validated ,
             country , 
             stated , 
             district,
             move
             } = this.state;    
             
             if(move){
                localStorage.setItem('id',this.state.id);
                return(
                    <Redirect to={{
                        pathname:'/centre/dashboard',
                    }}/>
                );
            }else{

        return (     
            <React.Fragment>
               
                <div style={{marginTop:40,marginBottom:0,width:1100}} className="container alert alert-primary text-center" role="alert">{title} Registraton</div>
                <div className="mx-auto" style={{width:1100,marginBottom:100,padding:30}}>
                <Form
                 noValidate
                 validated={validated}
                >
                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Name of the Organisation
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    name="Centre_name" 
                    onChange={this.updateState}
                     required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Type of Organization
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    name="Organizationtype" 
                    onChange={this.updateState}
                    as="select"
                     required>
                                         <option defaultValue>Choose...</option>
                                                            <option value="1">Banking, Mortgage</option>
                                                            <option value="2">Business Services</option>
                                                            <option value="3">Computer Software</option>
                                                            <option value="4">Electronics Manufacturing & Equipment</option>
                                                            <option value="5">Finance, Insurance & Real Estate</option>
                                                            <option value="6">Health</option>
                                                            <option value="7">Labor</option>
                                                            <option value="8">Teachers/Education</option>

                     </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                            <Form.Label column  sm={4}>Country</Form.Label>
                            <Col  sm={8}>
                    
                            <Form.Control onChange={this.updateState} 
                    name="country" 
                    as="select">
                                <option>..choose..</option>
                                 { country.map((country,ind) => <option key={ind} value={country[0]}>{country[2]}</option>
                            )}
                            </Form.Control>
                            </Col>
                            </Form.Group>


                            <Form.Group as={Row} >
                            <Form.Label column  sm={4}>State</Form.Label>
                            <Col  sm={8}>
                    
                            <Form.Control onChange={this.updateState} 
                    name="state" 
                    as="select">
                                <option>..choose..</option>
                                 { stated.map((stated,ind) => <option key={ind} value={stated[0]}>{stated[1]}</option>
                            )}
                            </Form.Control>
                            </Col>
                            </Form.Group>
                        
                            <Form.Group as={Row} >
                            <Form.Label column sm={4}>District</Form.Label>
                            <Col  sm={8}>
                    
                            <Form.Control onChange={this.updateState} 
                    name="district" 
                    as="select" required>
                                <option>..choose..</option>
                                {  district.map((district,id) => <option key={id} value={district[0]}>{district[1]}</option>
                            )}
                            </Form.Control>
                            </Col>
                            </Form.Group>

                            <Form.Group as={Row} >
                                <Form.Label column sm="4">
                                City
                                </Form.Label>
                                <Col sm="8">
                                <Form.Control type="text"
                                onChange={this.updateState}
                                name="city" 

                                placeholder="" required
                                />
                                </Col>
                            </Form.Group>

                            <div className="form-group row">
                            <h5 className="text-center"><b> Owner Details </b></h5>
                            </div>
                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Full Name
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    onChange={this.updateState} 
                    name="first_name" 
                    placeholder="" required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                    Role
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="text"
                    name="role" 
                    onChange={this.updateState}
                    as="select"
                     required>
                            <option defaultValue>Choose...</option>
                            <option value="1">Recruiter</option>
                            <option value="2">Administrative Assistant/Secretary</option>
                            <option value="3">Job Hiring Manager</option>
                            <option value="4">Human Resources</option>
                            <option value="5">Management</option>
                     </Form.Control>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                    Mobile No
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="number"
                    onChange={this.updateState}                  
                    name="Mobile" 
                    placeholder=""
                    maxLength="10"
                    required />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Form.Label column sm="4">
                    Email
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="email" 
                    name="email" 
                    onChange={this.updateState}
                    placeholder="" required />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                    <Form.Label column sm="4">
                     Date of Birth
                    </Form.Label>
                    <Col sm="8">
                    <Form.Control type="date"
                    name="dob"                     
                    onChange={this.updateState}
                    placeholder="" required/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} >
                                                <Form.Label column sm={4}>
                                                Gender
                                                </Form.Label>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Male"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios1" 
                                                 value="Male"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Female"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios2" 
                                                 value="Female"
                                                 required
                                                 />
                                                </Col>
                                                <Col  sm={2}>
                                                <Form.Check 
                                                 type="radio" 
                                                 label="Transgender"
                                                 onChange={this.updateState} 
                                                 name="gender" 
                                                 id="Radios3" 
                                                 value="Transgender"
                                                 required
                                                 />
                                                </Col>
                                                </Form.Group>

                          
                                                <Form.Group as={Row} >
                                                <Form.Label column sm="4">
                                                Identity Proof 
                                                </Form.Label>
                                                <Col sm="8">
                                                <Form.Control 
                                                name="IdProof" 
                                                onChange={this.updateState}
                                                as="select"
                                                required>
                                         <option defaultValue>Choose...</option>
                                                            <option value="1">Passport</option>
                                                            <option value="2">Pan Card</option>
                                                            <option value="3">Voter ID Card</option>
                                                            <option value="4">Aadhaar Card (UID)</option>
                                                            <option value="5">Driving License</option>
                                                    </Form.Control>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} >
                                                <Form.Label column sm="4">
                                                Upload File
                                                </Form.Label>
                                                <Col sm="8">
                                                <Form.Control type="file"
                                                onChange={this.updateState} 
                                                name="file_name" 
                                                autoComplete="false"
                                                placeholder="" required/>
                                                </Col>
                                                </Form.Group>

                            <Form.Group as={Row} >
                            <Form.Label column sm="4">
                            Password
                            </Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"
                            onChange={this.updateState} 
                            name="pwsd" 
                            autoComplete="false"
                            placeholder="" required/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} >
                            <Form.Label column sm="4">
                            confirm password 
                            </Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"
                            onChange={this.updateState} 
                            name="c_pwsd" 
                            autoComplete="false"
                            placeholder="" required/>
                            </Col>
                        </Form.Group>
                     

                        {/* <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}                      
                    <Form.Group>
                    <Col className="mx-auto" sm={2}>
                        <Button variant="primary"
                        onClick={this.handleSubmit}
                        type="button">
                            Submit
                        </Button>
                        </Col>
                    </Form.Group>
                      
                        </Form>
                  
                </div>
            </React.Fragment>
         );
        }
    }
}
 
export default withRouter(RegisterCentre);